import JobCard from "../JobCard";
import styles from "./JobList.module.css";

const JobList = ({
  jobs = [],
  onClick = () => null,
  queryObject,
  refetchJobs,
}) => {
  return (
    <div className={styles.container}>
      <ul className={styles.list}>
        {jobs.map((job) => {
          return (
            <li key={job.id}>
              <JobCard
                job={job}
                queryObject={queryObject}
                refetchJobs={refetchJobs}
                onClick={onClick(job.uid)}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default JobList;
