import { useQuery } from "@tanstack/react-query";
import app from "../../config/axiosConfig";

const _getCandidateMatchesStats = async () => {
  const response = await app.get(`api/candidate-matches-stats`);
  if (response.status < 200 || response.status > 299) {
    throw new Error("Error fetching my matches stats: " + response.statusText);
  }
  return response.data;
};

const useCandidateMatchesStats = (enabled = true) => {
  const { data, error, isLoading, refetch } = useQuery({
    queryKey: ["jobs", { type: "my-matches-stats" }],
    queryFn: () => _getCandidateMatchesStats(),
    enabled: enabled,
  });

  return {
    countJobs: data,
    error,
    isLoading: isLoading,
    refetchStats: refetch,
  };
};

export default useCandidateMatchesStats;
