import {
  deleteObject,
  getBlob,
  getDownloadURL,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { Dispatch } from "redux";
// @ts-ignore
import { storage } from "../config/firebase";

const MAX_FILE_SIZE = 10485760; // 10MB

export enum ENUM_FILE_TYPE {
  CV = "cv",
  TRANSCRIPTS = "transcripts",
  COVER_LETTER = "coverLetter",
  PROFILE_IMAGE = "profileImg",
  APPLICATION = "application",
}

export const checkFileSize = (file: File): boolean => {
  if (file.size > MAX_FILE_SIZE) {
    alert("The file is too big! Compress it below 10MB");
    return false;
  }
  return true;
};

export const uploadFile = async (
  userId: string,
  initialData: { [key: string]: string | null | string[] },
  file: File,
  path: string,
  dispatch: Dispatch<any>,
  updateAction: (data: { [key: string]: string | null | string[] }) => void,
  fileType: ENUM_FILE_TYPE,
  onSuccess?: (userData: {}) => Promise<void>
): Promise<void> => {
  const storageRef = ref(storage, path);
  const uploadTask = uploadBytesResumable(storageRef, file);

  uploadTask.on(
    "state_changed",
    (snapshot) => {},
    (error) => {
      console.log(`Upload failed: ${error.message}`);
    },
    async () => {
      try {
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
        let updatedData;

        if (fileType === ENUM_FILE_TYPE.TRANSCRIPTS) {
          updatedData = {
            ...initialData,
            [fileType]: [...(initialData[fileType] as string[]), downloadURL],
            [`${fileType}Path`]: [
              ...(initialData[`${fileType}Path`] as string[]),
              path,
            ],
          };
        } else {
          updatedData = {
            ...initialData,
            [fileType]: downloadURL,
            [`${fileType}Path`]: path,
          };
        }

        dispatch(updateAction(updatedData));
        if (onSuccess != null) {
          await onSuccess(updatedData);
        }
      } catch (error: any) {
        console.log(`Failed to get download URL: ${error.message}`);
      }
    }
  );
};

export const deleteFile = async (
  userId: string,
  initialData: { [key: string]: string | null | string[] },
  path: string | null,
  dispatch: Dispatch<any>,
  updateAction: (data: { [key: string]: string | null | string[] }) => void,
  fileType: ENUM_FILE_TYPE,
  index: number,
  onSuccess?: (userData: {}) => Promise<void>
): Promise<void> => {
  if (!path) {
    console.log("No file path provided!");
    return;
  }

  const fileRef = ref(storage, path);

  try {
    await deleteObject(fileRef);
    let updatedData;

    if (fileType === ENUM_FILE_TYPE.TRANSCRIPTS) {
      const updatedTranscripts = [...(initialData[fileType] as string[])];
      const updatedTranscriptsPath = [
        ...(initialData[`${fileType}Path`] as string[]),
      ];

      updatedTranscripts.splice(index, 1);
      updatedTranscriptsPath.splice(index, 1);

      updatedData = {
        ...initialData,
        [fileType]: updatedTranscripts,
        [`${fileType}Path`]: updatedTranscriptsPath,
      };
    } else {
      updatedData = {
        ...initialData,
        [fileType]: null,
        [`${fileType}Path`]: null,
      };
    }

    dispatch(updateAction(updatedData));

    if (onSuccess) {
      console.log("onSuccess");
      await onSuccess(updatedData);
    }
  } catch (error: any) {
    console.log(`Delete failed: ${error.message}`);
  }
};

export const copyFile = async (
  initialData: { [key: string]: string | null | string[] },
  sourcePath: string,
  destinationPath: string,
  dispatch: Dispatch<any>,
  updateAction: (data: { [key: string]: string | null | string[] }) => void,
  onSuccess?: (userData: {}) => Promise<void>
): Promise<void> => {
  try {
    // Step 1: Get the download URL of the source image
    const sourceRef = ref(storage, sourcePath);

    const blob = await getBlob(sourceRef);

    // Step 2: Upload the image to the destination path
    const destinationRef = ref(storage, destinationPath);
    const uploadTask = uploadBytesResumable(destinationRef, blob);

    uploadTask.on(
      "state_changed",
      (snapshot) => {},
      (error) => {
        console.log(`Upload failed: ${error.message}`);
      },
      async () => {
        // Step 3: Get the download URL of the newly uploaded image
        const newDownloadURL = await getDownloadURL(uploadTask.snapshot.ref);

        // Step 4: Dispatch and optionally call success callback
        const updatedData = {
          ...initialData,
          applicationCv: newDownloadURL,
          applicationCvPath: destinationPath,
        };

        dispatch(updateAction(updatedData));

        if (onSuccess != null) {
          await onSuccess(updatedData);
        }
      }
    );
  } catch (error: any) {
    console.log(`Failed to copy file: ${error.message}`);
  }
};
