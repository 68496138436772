import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { registerEmployer, verifyEmployer } from "../../app/functions.js";
import { IMAGES } from "../../constants/images.jsx";
import CredentialsStep from "./CredentialsStep.jsx";
import FirmStep from "./FirmStep.jsx";
import styles from "./RecruiterRegister.module.css";
import UserStep from "./UserStep.jsx";
import "yup-phone";

const steps = {
  CREDENTIALS: "credentials",
  USER: "user",
  FIRM: "firm",
};

// Min 5 characters, 1 upper case letter, 1 lower case letter, 1 numeric digit
const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/;

const validationSchema = yup
  .object()
  .shape({
    email: yup
      .string()
      // .email("Please enter a valid email")
      .required("Please enter an email"),
    password: yup
      .string()
      .matches(passwordRules, {
        message:
          "Password must be at least 5 characters, contain 1 uppercase letter, 1 lowercase letter, and 1 number",
      })
      .required("Password is required"),
    passwordConfirm: yup
      .string()
      .required("Please confirm your password")
      .when(["password"], (password, schema) =>
        password !== ""
          ? schema.equals(password, "Passwords must match")
          : schema
      ),
    firstName: yup.string().required("Please enter your first name"),
    lastName: yup.string().required("Please enter last name"),
    role: yup.string().required("Please enter your role in the company"),
    firmName: yup.string().required("Please enter firm name"),
    firmLogo: yup.mixed().test("fileSize", "Firm logo is required", (value) => {
      if (value?.length > 0) return true;
    }),
    firmPrimaryLocation: yup.string().required("Please enter your firm city"),
    firmOtherLocations: yup.array().of(yup.string()),
    firmUrl: yup
      .string()
      .matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        "Please enter correct website URL"
      )
      .required("Please enter firm website URL"),
    firmSize: yup.string().required("Please select firm size"),
    firmDescription: yup.string().required("Please enter firm bio"),
  })
  .required();

const RecruiterRegister = () => {
  const [activeStep, activateStep] = useState(steps.CREDENTIALS);
  const navigate = useNavigate();
  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit: handleFormSubmit,
    register,
    setError,
    trigger,
    watch,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      email: "",
      password: "",
      passwordConfirm: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
      role: "",
      firmName: "",
      firmFoundedYear: "",
      firmLogo: undefined,
      firmPrimaryLocation: "",
      firmOtherLocations: [],
      firmUrl: "",
      firmSize: "",
      firmDescription: "",
    },
    resolver: yupResolver(validationSchema),
  });

  const handleSubmit = handleFormSubmit(async (formData) => {
    const { email, password, ...employerData } = formData;

    try {
      const data = await registerEmployer(email, password, employerData);
      await verifyEmployer(data);
      navigate("/employer-registration-complete");
    } catch (error) {
      if (error.code === "auth/email-already-in-use") {
        setError("email", {
          type: "email_in_use",
          message: "Email already in use",
        });
        activateStep(steps.CREDENTIALS);
      } else {
        throw error;
      }
    }
  });

  return (
    <div className={styles.container}>
      <div className="flex flex-column justify-center items-center bg-white border-t-8 border-t-yellow-300 rounded-md p-3 w-[90%] sm:w-8/12 md:w-2/3 lg:w-1/3">
        <div>
          <img
            src={IMAGES.JLFULL}
            className="w-18 h-10 mb-0"
            alt="JustLawyers logo"
          />
          <h3 className="w-75 flex text-center items-center font-bold uppercase text-[#738397] justify-center">
            Employer
          </h3>
        </div>

        <div className="flex flex-column w-[80%]">
          <div className="flex flex-column">
            <form onSubmit={handleSubmit}>
              {activeStep === steps.CREDENTIALS && (
                <CredentialsStep
                  errors={errors}
                  onNext={() => {
                    activateStep(steps.USER);
                  }}
                  register={register}
                  trigger={trigger}
                />
              )}
              {activeStep === steps.USER && (
                <UserStep
                  control={control}
                  errors={errors}
                  onNext={() => {
                    activateStep(steps.FIRM);
                  }}
                  onPrev={() => {
                    activateStep(steps.CREDENTIALS);
                  }}
                  register={register}
                  trigger={trigger}
                />
              )}
              {activeStep === steps.FIRM && (
                <FirmStep
                  control={control}
                  errors={errors}
                  isSubmitting={isSubmitting}
                  onPrev={() => {
                    activateStep(steps.USER);
                  }}
                  register={register}
                  watch={watch}
                />
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecruiterRegister;
