import { Controller } from "react-hook-form";
import CreatableSelect from "react-select/creatable";
import { ESkills, TSkillOption } from "../../../types/candidate.types";

interface IProps {
  title?: string;
  placeholder?: string;
  //TODO: update types
  control: any;
  //TODO: update types
  handleSelectionSkills: (values: any, field: any) => void;
  fieldName: string;
  options: TSkillOption[];
  selectedSkills: TSkillOption[];
  isClearable?: boolean;
  isMulti?: boolean;
}

export const SkillsInputFormEnabled = ({
  title = "Skills",
  placeholder = "Type or select skills",
  control,
  handleSelectionSkills,
  fieldName,
  options,
  isClearable = true,
  isMulti = true,
}: IProps) => {
  return (
    <div className="my-2">
      <p className="font-bold">{title}</p>
      <Controller
        name={fieldName}
        control={control}
        render={({ field }) => {
          const selectedOptions = field.value?.map((skill: ESkills) => ({
            value: skill,
            label: skill,
          }));

          return (
            <CreatableSelect
              placeholder={placeholder}
              {...field}
              onChange={(values) => handleSelectionSkills(values, field)}
              value={selectedOptions}
              isClearable={isClearable}
              isMulti={isMulti}
              options={options}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          );
        }}
      />
    </div>
  );
};
