import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";

import { saveData } from "../../../app/dataSlice.js";
import { editUser } from "../../../app/functions.js";
import styles from "./CandidateData.module.css";
import Content1 from "./Content1.jsx";
import Content2 from "./Content2.jsx";
import Content3 from "./Content3.jsx";
import Content4 from "./Content4.jsx";

const EditCandidate = ({ show, handleClose }) => {
  const dispatch = useDispatch();
  const initialData = useSelector((state) => state.initialData);

  const [page, setPage] = useState("Page1");

  const save = async () => {
    const data = initialData;
    const uid = initialData.uid;
    saveData(uid, data);

    await editUser(data, dispatch, saveData);
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose} className="w-full">
      <Modal.Header
        className="w-full flex flex-col flex-col-reverse"
        closeButton
      >
        <Row className="w-full flex justify-center items-center">
          <Row className="w-full flex flex-row items-center justify-center">
            <Modal.Title className="w-fit font-normal text-xl">
              Edit your profile
            </Modal.Title>
          </Row>
          <Row className="max-w-full flex items-center justify-center">
            <Col className="max-w-full flex justify-between mt-8 gap-1">
              <Button
                className="text-xs"
                variant={page === "Page1" ? "activeTab" : "tab"}
                onClick={() => setPage("Page1")}
              >
                <p className="font-bold text-black">Profile</p>
              </Button>
              <Button
                className="text-xs"
                variant={page === "Page2" ? "activeTab" : "tab"}
                onClick={() => setPage("Page2")}
              >
                <p className="font-bold text-black">Education</p>
              </Button>
              <Button
                className="text-xs"
                variant={page === "Page3" ? "activeTab" : "tab"}
                onClick={() => setPage("Page3")}
              >
                <p className="font-bold text-black">Experience</p>
              </Button>
              <Button
                className="text-xs"
                variant={page === "Page4" ? "activeTab" : "tab"}
                onClick={() => setPage("Page4")}
              >
                <p className="font-bold text-black">Job Criteria</p>
              </Button>
            </Col>
          </Row>
        </Row>
      </Modal.Header>
      <Modal.Body className={styles.modalbod}>
        {page === "Page1" && <Content1 />}
        {page === "Page2" && <Content2 />}
        {page === "Page3" && <Content3 />}
        {page === "Page4" && <Content4 />}
      </Modal.Body>
      <Modal.Footer>
        <Row className="flex items-center justify-center w-full">
          <Col className="flex justify-center">
            <Button onClick={save}>Save changes</Button>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

export default EditCandidate;
