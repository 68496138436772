import { useMutation } from "@tanstack/react-query";
// @ts-ignore
import { auth, signInWithCustomToken } from "../../src/config/firebase";
// @ts-ignore
import app from "../config/axiosConfig";

const _loginUser = async (userData: { email: string; password: string }) => {
  try {
    const response = await app.post(`api/login`, userData);

    if (response.status < 200 || response.status > 299) {
      throw new Error(response.data.error);
    }

    return response.data;
  } catch (error: any) {
    throw error.response.data.error;
  }
};
const useLogin = () => {
  const {
    data: user,
    error,
    isPending: isLoading,
    mutate: loginUser,
  } = useMutation({
    mutationFn: _loginUser,
    onSuccess: async (data) => {
      await loginUserWithToken(data.customToken);
    },
  });

  const loginUserWithToken = async (token: string) => {
    try {
      await signInWithCustomToken(auth, token);
    } catch (error: any) {
      console.error("Error logging in:", error.message);
    }
  };

  return { user, error, isLoading, loginUser };
};

export default useLogin;
