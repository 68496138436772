import { useMutation, useQueryClient } from "@tanstack/react-query";
import app from "../../config/axiosConfig";

const _updateIncognito = async ({
  conversationId,
  incognito,
  userId,
}: {
  conversationId: string;
  incognito: string;
  userId: string;
}) => {
  const response = await app.put(`api/update-incognito`, {
    conversationId: conversationId,
    incognito: incognito,
    userId: userId,
  });

  if (response.status < 200 || response.status > 299) {
    throw new Error("Error updating incognito: " + response.statusText);
  }
  return response.data;
};

const useUpdateIncognito = () => {
  const queryClient = useQueryClient();

  const { isPending: isUpdating, mutateAsync: updateIncognito } = useMutation({
    mutationFn: _updateIncognito,

    onError: (error, data, context) => {
      throw new Error("Error updating incognito: " + error.message);
    },

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["user"] });
    },
  });

  return {
    isUpdating: isUpdating,
    updateIncognito: updateIncognito,
  };
};

export default useUpdateIncognito;
