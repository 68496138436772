// @ts-ignore
import { IMAGES } from "../../constants/images";

const MatchBadge = () => {
  return (
    <div
      style={{
        width: "138px",
        height: "33px",
        position: "absolute",
        top: "-1.1rem",
        left: "50%",
        transform: "translateX(-50%)",
        display: "flex",
        fontWeight: "bold",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#868BFF",
        color: "#19293D",
        borderRadius: "8px",
        boxShadow: "0px 1px 4px 0px #19293D57",
        gap: "8px",
        zIndex: 1,
      }}
    >
      <img src={IMAGES.MATCHED} width={20} height={16} />
      Matched
    </div>
  );
};

export default MatchBadge;
