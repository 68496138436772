import classnames from "classnames";
import styles from "./MatchPercentageBadge.module.css";

const MatchPercentageBadge = ({ inline = false, percentage, style }) => {
  return (
    <div
      className={classnames(styles.container, { [styles.inline]: inline })}
      style={{ ...style }}
    >
      {percentage}% match
    </div>
  );
};

export default MatchPercentageBadge;
