import { useState } from "react";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { saveData } from "../../app/dataSlice.js";
import { signInOrLogin } from "../../app/functions";
import { updateInitialData } from "../../app/initialDataSlice";
import { login } from "../../app/userRedux/userSlice.js";
import { SkillsInput } from "../../components/inputs/SkillsInput";
import {
  belgiumCitiesOptions,
  firmsOptions,
  languagesOptions,
  skillOptions,
} from "../../constants/Data.js";
import { IMAGES } from "../../constants/images.jsx";
import styles from "./CandidateData.module.css";
import Layout from "./Layout.jsx";

const ANNUAL_REMUNERATION_OPTIONS = {
  NOT_DISCLOSE: "Prefer not to disclose",
  MORE: "More than 250 K€",
};

const CandidateData4 = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const initialData = useSelector((state) => state.initialData);
  const userData = useSelector((state) => state.user.userData);
  const [salaryRange, setSalaryRange] = useState("");
  const [expertise, setExpertise] = useState(false);
  const [languages, setLanguages] = useState(false);
  const [citiesList, setCitiesList] = useState(false);
  const [isStudent, setIsStudent] = useState(false);
  const [experience, setExperience] = useState(initialData?.experience ?? null);
  const [desiredRemunerationValue, setDesiredRemunerationValue] = useState(0);
  const [isDisclose, setIsDisclose] = useState(false);
  const [pluse, setPluse] = useState(false);
  const [RemunerationRange, setRemunerationRange] = useState();

  const handleChangeLanguages = (selectedOptions) => {
    const newLanguages = selectedOptions
      ? selectedOptions.map((option) => option.label)
      : [];
    dispatch(updateInitialData({ selectedLanguages: newLanguages }));
  };

  const handleInputRemuneration = (e) => {
    setDesiredRemunerationValue(e.target.value);
    let annualRemuneration = "";
    if (isDisclose) {
      annualRemuneration = ANNUAL_REMUNERATION_OPTIONS.NOT_DISCLOSE;
      setRemunerationRange(annualRemuneration);
    } else if (pluse) {
      annualRemuneration = ANNUAL_REMUNERATION_OPTIONS.MORE;
      setRemunerationRange(annualRemuneration);
    } else {
      annualRemuneration = `${e.target.value} K€`;
      setRemunerationRange(annualRemuneration);
    }
    dispatch(updateInitialData({ annualRemuneration: annualRemuneration }));

    const remuneration = pluse ? 250 : e.target.value;

    dispatch(updateInitialData({ desiredRemuneration: remuneration }));
  };

  const handleInputExperience = (e) => {
    setExperience(e.target.value);
    const exp = isStudent ? 0 : e.target.value;

    dispatch(updateInitialData({ experience: exp }));
  };

  const handleSelectionExpertise = (selectedOptions) => {
    const arrayOfLabels = selectedOptions.map((obj) => obj.label);

    const updatedSkillsOptions = selectedOptions.flatMap(
      (option) => skillOptions[option.label] || []
    );

    const updatedSelectedSkills = initialData.skills.filter((skill) =>
      updatedSkillsOptions.some((option) => option.label === skill)
    );

    dispatch(
      updateInitialData({
        expertise: arrayOfLabels,
        skillsOptions: updatedSkillsOptions,
        skills: updatedSelectedSkills,
      })
    );
  };

  const handleSelectionSkills = (selectedSkills) => {
    const arrayOfLabels = selectedSkills.map((obj) => obj.label);

    dispatch(updateInitialData({ skills: arrayOfLabels }));
  };

  const handleSelectionCities = (selectedOptions) => {
    const newCities = selectedOptions
      ? selectedOptions.map((option) => option.label)
      : [];
    dispatch(updateInitialData({ cities: newCities }));
  };
  const handleSelectionFirmsToMatch = (selectedOptions) => {
    const newSelectedFirms = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    const newNotSelectedFirms = initialData.nottomatch.filter(
      (firm) => !newSelectedFirms.includes(firm)
    );

    dispatch(
      updateInitialData({
        tomatch: newSelectedFirms,
        nottomatch: newNotSelectedFirms,
      })
    );
  };

  const handleSelectionFirmsNotToMatch = (selectedOptions) => {
    const newSelectedFirms = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    const newNotSelectedFirms = initialData.tomatch.filter(
      (firm) => !newSelectedFirms.includes(firm)
    );

    dispatch(
      updateInitialData({
        tomatch: newNotSelectedFirms,
        nottomatch: newSelectedFirms,
      })
    );
  };

  const getFilteredOptions = (selectedList) =>
    firmsOptions
      .filter((firm) => !selectedList.includes(firm.value))
      .map((firm) => ({ label: firm.value, value: firm.value }));

  const handelJobSearch = async () => {
    if (initialData.cities.length === 0) {
      setCitiesList(true);
      setTimeout(() => {
        setCitiesList(false);
      }, 3000);
      return;
    }
    if (!initialData.annualRemuneration) {
      setSalaryRange(true);
      setTimeout(() => {
        setSalaryRange(false);
      }, 3000);
      return;
    }

    if (initialData.selectedLanguages.length === 0) {
      setLanguages(true);
      setTimeout(() => {
        setLanguages(false);
      }, 3000);
      return;
    }
    if (initialData.expertise.length === 0) {
      setExpertise(true);
      setTimeout(() => {
        setExpertise(false);
      }, 3000);
      return;
    }

    const uid = userData.uid;

    const data = {
      ...initialData,
      email: userData.email,
      uid: uid,
      isEmployer: false,
      isIncognito: true,
    };

    saveData(uid, data);

    await signInOrLogin({ ...data }, dispatch, saveData);

    dispatch(
      login({
        authData: { ...userData, isEmployer: false },
        userData: {
          ...data,
        },
      })
    );

    navigate("/job-board");
  };

  const prevWindow = (e) => {
    navigate("/candidatedata2");
  };

  return (
    <Layout onBack={prevWindow} onNext={handelJobSearch} step={4}>
      <section className={styles.section}>
        <h1 className={styles.title}>Let's find your perfect match!</h1>
        <p>
          Your input powers our AI-driven algorithm and helps us deliver ideal
          job matches for you. The more precise your information, the better
          your opportunities will be. Let's get started.
        </p>
        <div className={styles.field}>
          <label>
            Preferred locations*
            <div className={styles.tooltipTrigger}>
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip placement="top" className="in" id="tooltip-top">
                    Please select all the cities you'd like to work in.
                  </Tooltip>
                }
              >
                <img src={IMAGES.QUESTIONMARK} height={17.5} width={17.5} />
              </OverlayTrigger>
            </div>
          </label>
          <CreatableSelect
            isClearable
            placeholder="Select cities"
            defaultValue={[]}
            isMulti
            name="cities"
            options={belgiumCitiesOptions}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={handleSelectionCities}
            value={initialData.cities.map((city) => ({
              label: city,
              value: city,
            }))}
            required
          />
          {citiesList && (
            <>
              <p className="text-red-400">Please enter the cities</p>
            </>
          )}
        </div>
        <div className={styles.field}>
          <label>
            Preferred minimal annual remuneration*
            <div className={styles.tooltipTrigger}>
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip placement="top" className="in" id="tooltip-top">
                    Your remuneration details will remain confidential. This
                    information will only be used to improve your job matching
                    results.
                  </Tooltip>
                }
              >
                <img src={IMAGES.QUESTIONMARK} height={17.5} width={17.5} />
              </OverlayTrigger>
            </div>
          </label>
          <Form.Group className="flex flex-column w-100 mt-2">
            <div className="flex flex-col md:flex-row items-start md:items-center justify-between">
              <Form.Check // prettier-ignore
                type="switch"
                id="enumerationDisclose"
                label="Prefer not to disclose"
                checked={isDisclose ?? false}
                onChange={(e) => {
                  dispatch(
                    updateInitialData({
                      annualRemuneration:
                        ANNUAL_REMUNERATION_OPTIONS.NOT_DISCLOSE,
                    })
                  );
                  setIsDisclose(e.target.checked);
                }}
              />
              <Form.Check // prettier-ignore
                type="switch"
                id="enumerationMax"
                label="More than €250K"
                checked={pluse ?? false}
                onChange={(e) => {
                  let annualRemuneration = ANNUAL_REMUNERATION_OPTIONS.MORE;
                  if (isDisclose) {
                    annualRemuneration =
                      ANNUAL_REMUNERATION_OPTIONS.NOT_DISCLOSE;
                  }
                  dispatch(
                    updateInitialData({
                      annualRemuneration: annualRemuneration,
                    })
                  );
                  if (e.target.checked) {
                    setDesiredRemunerationValue(250);
                    dispatch(updateInitialData({ desiredRemuneration: 250 }));
                  }
                  setPluse(e.target.checked);
                }}
              />
            </div>
            <div>
              <Form.Range
                value={desiredRemunerationValue}
                min={0}
                max={250}
                step={10}
                disabled={isDisclose}
                onChange={handleInputRemuneration}
              />
              <div className="flex flex-col gap-2">
                <div className="text-xs">
                  Preferred Remuneration{" "}
                  <span className="font-bold">
                    €{desiredRemunerationValue}K
                  </span>
                </div>
                <div className="text-xs">
                  Not sure about your worth, check out our remuneration guide:
                  <a
                    target="_blank"
                    className="font-bold pointer underline"
                    href="https://justlawyers.knowyourworth.be"
                  >
                    {" "}
                    JustLawyers KNOWYOURWORTH
                  </a>
                </div>
              </div>
            </div>
          </Form.Group>
          {salaryRange && (
            <>
              <p className="text-red-400">Please select the Desire salary</p>
            </>
          )}
        </div>

        <div className={styles.field}>
          <label>
            Languages*
            <div className={styles.tooltipTrigger}>
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip placement="top" className="in" id="tooltip-top">
                    Select only your native / fluent languages
                  </Tooltip>
                }
              >
                <img src={IMAGES.QUESTIONMARK} height={17.5} width={17.5} />
              </OverlayTrigger>
            </div>
          </label>
          <CreatableSelect
            isClearable
            placeholder="Select languages"
            defaultValue={[]}
            isMulti
            name="languages"
            options={languagesOptions}
            className="basic-multi-select"
            classNamePrefix="select"
            value={initialData.selectedLanguages.map((lang) => ({
              label: lang,
              value: lang,
            }))}
            onChange={handleChangeLanguages}
          />
          {languages && (
            <>
              <p className="text-red-400">Please select the languages</p>
            </>
          )}
        </div>

        <div className={styles.field}>
          <label>Expertise*</label>
          <Select
            isClearable
            placeholder="Select or type your expertises"
            defaultValue={[]}
            isMulti
            name="type"
            options={Object.keys(skillOptions).map((key) => ({
              value: key,
              label: key,
            }))}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={handleSelectionExpertise}
            value={initialData.expertise?.map((exp) => ({
              label: exp,
              value: exp,
            }))}
          />
          {expertise && (
            <>
              <p className="text-red-400">Please enter the expertise</p>
            </>
          )}
        </div>

        {initialData.expertise.length > 0 && (
          <SkillsInput
            subtitle="Select your skills. Is your skill not in the list? Add a custom
              one by typing."
            placeholder="Select or type your skills"
            defaultValue={[]}
            name="skills"
            options={initialData.skillsOptions}
            wrapperClassName={styles.field}
            handleSelectionSkills={handleSelectionSkills}
            values={initialData.skills || []}
          />
        )}

        <div className={styles.field}>
          <label>Experience*</label>
          <Form.Group className="flex flex-column w-100 mt-2">
            <div className="flex flex-col md:flex-row items-start md:items-center justify-between">
              <Form.Check // prettier-ignore
                type="switch"
                id="student"
                label="I am a student"
                checked={isStudent ?? false}
                onChange={(e) => {
                  setIsStudent(e.target.checked);
                  if (e.target.checked) {
                    setExperience(0);
                    dispatch(updateInitialData({ experience: 0 }));
                  }
                  dispatch(updateInitialData({ isStudent: e.target.checked }));
                }}
              />
            </div>
            <div>
              <Form.Range
                disabled={isStudent}
                value={experience}
                min={0}
                max={7}
                step={1}
                onChange={handleInputExperience}
              />
              <div className="text-xs">
                Years of experience{" "}
                <span className="font-bold">{experience}</span>
              </div>
            </div>
          </Form.Group>
          {experience == null && (
            <>
              <p className="text-red-400">Please select the Experience</p>
            </>
          )}
        </div>

        <div className={styles.field}>
          <label>Are there specific law firms you'd like to follow?</label>
          <div className="my-2 bg-green-100 px-2 py-1 rounded-md">
            <CreatableSelect
              isClearable
              placeholder="Select law firm(s)"
              defaultValue={[]}
              isMulti
              name="type"
              options={getFilteredOptions(initialData.nottomatch)}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={handleSelectionFirmsToMatch}
              value={initialData.tomatch.map((name) => ({
                label: name,
                value: name,
              }))}
            />
          </div>
        </div>

        <div className={styles.field}>
          <label>
            Are there law firms you don't want to be matched with?
            <div className={styles.tooltipTrigger}>
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip placement="top" className="in" id="tooltip-top">
                    If so, please let us know, and we will ensure that they are
                    excluded from your updates. By excluding a law firm, you
                    will no longer appear in their overview, nor will you
                    receive notifications about job opportunities from that
                    firm.
                  </Tooltip>
                }
              >
                <img src={IMAGES.QUESTIONMARK} height={17.5} width={17.5} />
              </OverlayTrigger>
            </div>
          </label>
          <div className="my-2 bg-red-100 px-2 py-1 rounded-md">
            <CreatableSelect
              isClearable
              placeholder="Select law firm(s)"
              defaultValue={[]}
              isMulti
              name="type"
              options={getFilteredOptions(initialData.tomatch)}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={handleSelectionFirmsNotToMatch}
              value={initialData.nottomatch.map((name) => ({
                label: name,
                value: name,
              }))}
            />
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default CandidateData4;
