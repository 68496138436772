import { useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { BiMessage } from "react-icons/bi";
import { MdOutlineMenu } from "react-icons/md";
import { PiSuitcaseLight } from "react-icons/pi";
import { useDispatch, useSelector } from "react-redux";
import { DotLoader } from "react-spinners";
import { saveData } from "../../app/dataSlice.js";
import { editUser, jobApply } from "../../app/functions";
import { updateInitialData } from "../../app/initialDataSlice.js";
import { IMAGES } from "../../constants/images";
import { experienceTotal } from "../../utils/helpers.js";
import ApplicationFinished from "./ApplicationFinished";
import { PAGES } from "./modalCandidateJobApply.jsx";

const ApplicationPreview = ({
  page,
  setPage,
  handleClose,
  setApplied,
  refetchJobs,
}) => {
  const dispatch = useDispatch();
  const initialData = useSelector((state) => state.initialData);
  const user = useSelector((state) => state.data.value);

  const [isApplying, setIsApplying] = useState(false);

  const sendApplication = async () => {
    try {
      setIsApplying(true);
      const job_id = initialData.selectedJob.uid;
      const employer_id = initialData.selectedJob.userid;
      const user_id = initialData.uid;
      const cv = initialData?.applicationCv ?? "";
      const coverLetter = initialData?.applicationCoverLetter ?? "";
      const transcripts = initialData?.transcripts ?? "";

      await jobApply(
        job_id,
        user_id,
        cv,
        coverLetter,
        transcripts,
        employer_id
      );
      setIsApplying(false);
      setPage(PAGES.SUCCESS);

      const updatedData = {
        ...initialData,
        applicationCoverLetter: null,
        applicationCoverLetterPath: null,
        applicationCv: null,
        applicationCvPath: null,
      };

      await editUser(updatedData, dispatch, saveData);
      dispatch(updateInitialData(updatedData));
    } catch (error) {
      console.error(error);
      setIsApplying(false);
    }
  };

  const handleOnClick = () => {
    handleClose();
    refetchJobs();
    setApplied(true);
  };

  return (
    <>
      {page === PAGES.SUCCESS ? (
        <>
          <ApplicationFinished handleOnClick={handleOnClick} />
        </>
      ) : (
        <>
          <Row className="text-black text-center mt-3">
            <p className="font-bold text-2xl mb-1">Preview Application</p>
            <p>
              Please carefully review the information below before submitting
              your application.
            </p>
          </Row>
          {/* jobs info */}
          <Row className="flex flex-column items-center my-3 text-sm">
            <Row>
              <Col>
                <p className="flex flex-col md:flex-row text-center md:text-start items-center mb-1">
                  <PiSuitcaseLight size={20} className="mr-2" />
                  <span className="text-sm font-semibold">
                    {experienceTotal(user)}
                  </span>
                </p>

                <p className="flex flex-col md:flex-row text-center md:text-start items-center mb-1">
                  <MdOutlineMenu size={20} className="mr-2" />
                  {user?.expertise[0] ? (
                    <>{user?.expertise?.join(" | ")}</>
                  ) : (
                    <>
                      <p>No expertise is added</p>
                    </>
                  )}
                </p>
              </Col>
              <Col className="flex flex-column justify-start items-end">
                {/* <p className="flex flex-col md:flex-row text-center md:text-start items-center mb-1">
                  <CiLocationOn size={20} className="mr-2" />
                  {initialData?.location == "" ? (
                    <>Location not added</>
                  ) : (
                    initialData?.location
                  )}
                </p> */}

                <p className="flex flex-col md:flex-row text-center md:text-start items-center mb-1">
                  <BiMessage size={20} className="mr-2" />
                  {user?.selectedLanguages[0] ? (
                    <>{user?.selectedLanguages.join(" | ")}</>
                  ) : (
                    <>
                      <p>No language is added</p>
                    </>
                  )}
                </p>
              </Col>
            </Row>
            <Row className="mt-3">
              <p className="text-justify">
                {initialData?.description == "" ? (
                  <>No description added.</>
                ) : (
                  <>{initialData?.description}</>
                )}
              </p>
            </Row>
          </Row>
          {/* jobs  attachments */}
          <Row className="flex flex-column items-center my-3">
            <Row className="flex justify-center gap-x-5 ">
              {initialData.applicationCv != null &&
                initialData.applicationCv !== "" && (
                  <Row
                    className="flex flex-column items-center text-center bg-[#F1F1F5] p-2 cursor-pointer w-[32%] rounded-md "
                    onClick={() =>
                      window.open(initialData.applicationCv, "_blank")
                    }
                  >
                    {/* <img src={IMAGES.BOOK} className="h-12 w-16" />
                  <p>CV</p> */}
                    <label
                      className="flex flex-column items-center gap-y-2  cursor-pointer "
                      for="newCL"
                    >
                      <img className="" src={IMAGES.BOOK} />
                      CV
                    </label>
                  </Row>
                )}
              {initialData.applicationCoverLetter != null &&
                initialData.applicationCoverLetter !== "" && (
                  <Row
                    className="flex flex-column items-center text-center bg-[#F1F1F5] p-2 cursor-pointer w-[32%] rounded-md ml-2"
                    onClick={() =>
                      window.open(initialData.applicationCoverLetter, "_blank")
                    }
                  >
                    <label
                      className="flex flex-column items-center gap-y-2  cursor-pointer "
                      for="newCL"
                    >
                      <img className="" src={IMAGES.BOOK} />
                      Cover letter
                    </label>
                  </Row>
                )}
            </Row>
          </Row>
          {/* jobs */}

          {/* buttons */}
          <Row className="flex flex-column items-center gap-y-3 mb-3">
            <button
              disabled={isApplying}
              className="bg-[#46D6EA] w-100 py-2 rounded-md font-bold  border-1 border-[#46D6EA] cursor-pointer hover:bg-transparent flex justify-center gap-2"
              onClick={async () => sendApplication()}
            >
              Send Application{" "}
              {isApplying && <DotLoader color="#000" size={"18px"} />}
            </button>
            <button
              disabled={isApplying}
              className="py-2 rounded-md w-100 font-bold  border-1 border-black cursor-pointer hover:bg-transparent"
              onClick={() => setPage(PAGES.APPLY)}
            >
              Back
            </button>
          </Row>
        </>
      )}
    </>
  );
};

export default ApplicationPreview;
