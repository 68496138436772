import classNames from "classnames";
import { useState } from "react";
import { Container, Navbar, Offcanvas } from "react-bootstrap";
import { AiOutlinePlus } from "react-icons/ai";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { store } from "../app/store.js";
import { logout } from "../app/userRedux/userSlice.js";
import MessagesNotification from "../components/MessagesNotification.jsx";
import Notifications from "../components/Notifications";
import RecruiterDropDown from "../components/RecruiterDropDown";
import { IMAGES } from "../constants/images";
import useLogout from "../hooks/useLogout";
import styles from "./Navbar.module.css";
import { Alert } from "react-bootstrap";
import { useConversation } from "../context/ConversationContext";

const NavBarItem = ({ children, to }) => {
  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        classNames(styles.link, { [styles.active]: isActive })
      }
    >
      {children}
    </NavLink>
  );
};

const NavbarRec = () => {
  const { activeConversationId } = useConversation();
  const { logoutUser } = useLogout();
  const navigate = useNavigate();

  const [showMenu, setShowMenu] = useState(false);
  const [showBanner, setShowBanner] = useState(true);

  const handleMenuOpen = () => {
    setShowMenu(true);
  };

  const handleMenuClose = () => {
    setShowMenu(false);
  };

  const handleItemClick = () => {
    setShowMenu(false); // Close the menu when an item is clicked
  };

  const handleEditUser = () => {
    // Add logic for handling Edit User action
    console.log("Edit User clicked");
  };

  const handleSignOut = async () => {
    logoutUser(null, {
      onSuccess: () => {
        store.dispatch(logout());
        localStorage.clear();
      },
    });
  };

  return (
    <>
      <Alert
        dismissible
        show={showBanner}
        onClose={() => setShowBanner(false)}
        className="text-center rounded-none bg-[#46D6EA] text-[#19293D] font-medium min-h-[45px] m-0 p-2 [&>.btn-close]:p-3"
      >
        Welcome to our Beta version! Let us know on{" "}
        <a href="mailto:support@justlawyers.app" className="underline">
          support@justlawyers.app
        </a>{" "}
        if you encounter any issues or have suggestions to improve the platform
        —we appreciate your feedback!
      </Alert>
      <div
        className={styles.navbar}
        style={{
          borderBottom: "5px solid #ffc83a",
        }}
      >
        <Link to="/dashboard" className={styles.logo}>
          <img
            className="logo_css cursor-pointer"
            src={IMAGES.JLFULL}
            alt="Logo"
          />
        </Link>
        <div className={styles.links}>
          <NavBarItem to="/dashboard">Dashboard</NavBarItem>
          <NavBarItem to="/chat-room" onClick={handleItemClick}>
            Messages
            <MessagesNotification activeConversationId={activeConversationId} />
          </NavBarItem>
          <NavBarItem to="/my-jobs">My Jobs</NavBarItem>
          <NavBarItem to="/candidate-board">Candidate Board</NavBarItem>
          <NavBarItem to="/recruiter-profile">My Law Firm</NavBarItem>
          <Link to="/create-job" className={styles.link_newJob}>
            <AiOutlinePlus className="mr-2" /> New Job
          </Link>

          <div className="justify-center align-items-center flex">
            <Notifications />
            <RecruiterDropDown
              handleEditUser={handleEditUser}
              handleSignout={handleSignOut}
              navigate={navigate}
            />
          </div>
        </div>
      </div>
      <Navbar
        expand="lg"
        className="inline-block custom:hidden bg-body-tertiary mb-3 w-full"
      >
        <Container fluid>
          <Link to="/dashboard" className={styles.logo}>
            <img
              className="logo_css cursor-pointer"
              src={IMAGES.JLFULL}
              alt="logo"
            />
          </Link>
          <Navbar.Toggle
            aria-controls={`offcanvasNavbar-expand`}
            onClick={handleMenuOpen}
            className="bg-white"
          />
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand`}
            aria-labelledby={`offcanvasNavbarLabel-expand`}
            placement="end"
            onHide={handleMenuClose}
            show={showMenu}
          >
            <Offcanvas.Header closeButton className="flex justify-center">
              <Offcanvas.Title>
                <Link
                  to="/dashboard"
                  className={styles.logo}
                  onClick={handleItemClick}
                >
                  <img
                    className="logo_css cursor-pointer"
                    src={IMAGES.JLFULL}
                    alt="logo"
                  />
                </Link>
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className="">
              <div className="flex flex-col-reverse justify-center items-center gap-y-3">
                <NavBarItem to="/dashboard" onClick={handleItemClick}>
                  Dashboard
                </NavBarItem>
                <NavBarItem to="/chat-room" onClick={handleItemClick}>
                  <p className="text-xl">Messages</p>
                  <MessagesNotification />
                </NavBarItem>
                <NavBarItem to="/my-jobs" onClick={handleItemClick}>
                  My Jobs
                </NavBarItem>
                <NavBarItem to="/candidate-board" onClick={handleItemClick}>
                  Candidate Board
                </NavBarItem>
                <NavBarItem to="/recruiter-profile" onClick={handleItemClick}>
                  My Law Firm
                </NavBarItem>
                <Link
                  to="/create-job"
                  className={styles.link_newJob}
                  onClick={handleItemClick}
                >
                  <AiOutlinePlus className="mr-2" /> New Job
                </Link>
              </div>
              <div
                style={{
                  padding: "8px",
                  cursor: "pointer",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  position: "absolute",
                  bottom: 30,
                  width: "100%",
                  flexDirection: "column",
                }}
              >
                <RecruiterDropDown
                  handleEditUser={handleEditUser}
                  handleSignout={handleSignOut}
                  navigate={navigate}
                  type="responsive"
                />
              </div>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </>
  );
};

export default NavbarRec;
