export const experienceTotal = (candidate) => {
  if (candidate.isStudent) {
    return "Student";
  }

  if ((candidate.employmentHistory || []).length === 0) {
    return "No experience";
  }

  if (candidate.experience === 0) {
    return "Less than 1 year";
  }

  return `${candidate.experience} year(s) experience`;
};
