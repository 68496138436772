import { useQuery } from "@tanstack/react-query";
import app from "../../config/axiosConfig";

const _getJob = async (jobId: string, userId?: string) => {
  const url = userId ? `api/jobs/${jobId}/${userId}` : `api/jobs/${jobId}`;
  const response = await app.get(url);
  if (response.status < 200 || response.status > 299) {
    throw new Error("Error fetching job: " + response.statusText);
  }
  return response.data;
};

const useJob = (jobId: string, enabled = true, userId?: string) => {
  const { data, error, isLoading, refetch } = useQuery({
    queryKey: ["jobs", jobId, userId],
    queryFn: () => _getJob(jobId, userId),
    enabled: enabled,
  });

  return {
    job: data,
    error,
    isJobLoading: isLoading,
    refetch: refetch,
  };
};

export default useJob;
