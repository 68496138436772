import classNames from "classnames";
import { useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { AiFillDelete, AiOutlineUpload } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";

import { updateInitialData } from "../../../app/initialDataSlice.js";
import anonymousIcon from "../../../assets/icons/anonymous.png";
import { IMAGES } from "../../../constants/images.jsx";
import {
  checkFileSize,
  deleteFile,
  ENUM_FILE_TYPE,
  uploadFile,
} from "../../../utils/crudFiles.ts";
import styles from "./CandidateData.module.css";

export default function Content1() {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user.userData);
  const initialData = useSelector((state) => state.initialData);

  const handleUploadProfilePhoto = async (event) => {
    const file = event.target.files[0];

    if (!file || !checkFileSize(file)) {
      return;
    }
    const filePath = `profileImg/${file.size}${file.name}`;

    await uploadFile(
      userData.uid,
      initialData,
      file,
      filePath,
      dispatch,
      updateInitialData,
      ENUM_FILE_TYPE.PROFILE_IMAGE
    );
  };

  const handleDeleteProfilePicture = async () => {
    await deleteFile(
      userData.uid,
      initialData,
      initialData.profileImgPath,
      dispatch,
      updateInitialData,
      ENUM_FILE_TYPE.PROFILE_IMAGE
    );
  };

  return (
    <div className={styles.innercontainer}>
      <section className={styles.section}>
        <h2 className={styles.title}>
          Complete Your Profile to unlock Personalised Job Matches
        </h2>
        <p>
          Rest assured; any information marked with{" "}
          <img src={anonymousIcon} className={styles.anonymousIcon} /> will
          remain hidden from law firms by default. This ensures you have full
          control over the information you choose to share.
        </p>
        <div className={styles.field}>
          <label htmlFor="firstName">
            First Name
            <img src={anonymousIcon} className={styles.anonymousIcon} />
          </label>
          <input
            id="firstName"
            className={styles.input}
            placeholder="First Name"
            value={initialData.name}
            required
            onChange={(e) =>
              dispatch(updateInitialData({ name: e.target.value }))
            }
          />
        </div>
        <div className={styles.field}>
          <label htmlFor="lastName">
            Last Name
            <img src={anonymousIcon} className={styles.anonymousIcon} />
          </label>
          <input
            id="lastName"
            className={styles.input}
            placeholder="Last Name"
            value={initialData.surname}
            required
            onChange={(e) =>
              dispatch(updateInitialData({ surname: e.target.value }))
            }
          />
        </div>
        <div className={styles.field}>
          <label>
            Profile Image
            <img src={anonymousIcon} className={styles.anonymousIcon} />
          </label>
          <div className="flex justify-between w-100">
            <label
              htmlFor="profileImg"
              className={`flex items-center justify-center bg-[#374b64] rounded-md text-white py-2 ${initialData.profileImgPath == null && "cursor-pointer"} w-100`}
            >
              {initialData.profileImgPath != null ? (
                <>
                  <AiFillDelete
                    size={22}
                    className="mr-3 cursor-pointer"
                    onClick={async () => handleDeleteProfilePicture()}
                  />
                  Delete uploaded Profile Image
                </>
              ) : (
                <>
                  <AiOutlineUpload size={22} className="mr-3" />
                  Click to Upload Profile Image
                </>
              )}
            </label>
            {initialData.profileImgPath == null && (
              <input
                id="profileImg"
                type="file"
                name="profileImg"
                className="hidden"
                onChange={async (event) => handleUploadProfilePhoto(event)}
                accept=".png, .jpg, .jpeg"
              />
            )}
          </div>
        </div>
        <div className={styles.field}>
          <label>About me</label>
          <textarea
            className={styles.input}
            placeholder="Write a description"
            value={initialData.description}
            rows={4}
            onChange={(e) =>
              dispatch(updateInitialData({ description: e.target.value }))
            }
          />
        </div>

        <div className={styles.field}>
          <label>
            What is your status?
            <div className={styles.tooltipTrigger}>
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip placement="right" className="in" id="tooltip-right">
                    Select your job-seeking status:{" "}
                    <strong>Actively Looking</strong> or{" "}
                    <strong>Casually Exploring</strong>. Law firms will see your
                    current status. You can adjust it anytime.
                  </Tooltip>
                }
              >
                <img
                  src={IMAGES.QUESTIONMARK}
                  style={{ height: "17.5px", width: "17.5px" }}
                />
              </OverlayTrigger>
            </div>
          </label>
          <div className={styles.status}>
            <button
              className={classNames(styles.statusButton, {
                [styles.statusButtonActive]:
                  initialData.searchingStatus === "actively looking",
              })}
              onClick={() => {
                dispatch(
                  updateInitialData({ searchingStatus: "actively looking" })
                );
              }}
            >
              Actively looking
            </button>
            <button
              className={classNames(styles.statusButton, {
                [styles.statusButtonActive]:
                  initialData.searchingStatus === "casually looking",
              })}
              onClick={() => {
                dispatch(
                  updateInitialData({ searchingStatus: "casually looking" })
                );
              }}
            >
              Casually exploring
            </button>
          </div>
        </div>
      </section>
    </div>
  );
}
