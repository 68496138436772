import { useMutation } from "@tanstack/react-query";
// @ts-ignore
import app from "../config/axiosConfig";

export const errorLogger = async (error: Error) => {
  try {
    const errorDetails = {
      message: error?.message ?? "",
      stack: error?.stack ?? "",
      url: window?.location?.href ?? "",
    };

    const response = await app.post(`api/error-logger`, {
      error: errorDetails,
    });

    if (response.status < 200 || response.status > 299) {
      throw new Error(response.data.error);
    }
    return response.data;
  } catch (error: any) {
    throw error.response.data.error;
  }
};
const useErrorLogger = () => {
  const {
    data,
    error,
    isPending: isLoading,
    mutate: logError,
  } = useMutation({
    mutationFn: errorLogger,
  });

  return { data, error, isLoading, logError };
};

export default useErrorLogger;
