import {
  collection,
  doc,
  getDocs,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../config/firebase";

const useFetchConversationsForJob = (jobId, jobOwnerId) => {
  const [conversations, setConversations] = useState([]);
  const [unreadCounts, setUnreadCounts] = useState({});
  const [totalReceivedCounts, setTotalReceivedCounts] = useState({});

  useEffect(() => {
    const fetchUnreadMessagesCount = async (conversationId) => {
      const messagesRef = collection(
        db,
        "conversations",
        conversationId,
        "messages"
      );
      const q = query(messagesRef, where("read", "==", false));
      const querySnapshot = await getDocs(q);
      return querySnapshot.size; // Number of unread messages
    };

    const fetchReceivedMessagesCount = async (conversationId) => {
      const messagesRef = collection(
        db,
        "conversations",
        conversationId,
        "messages"
      );

      const q = query(messagesRef, where("sentBy", "!=", jobOwnerId));
      const querySnapshot = await getDocs(q);
      return querySnapshot.size;
    };

    const unsubscribe = onSnapshot(
      query(
        collection(db, "conversations"),
        where("job", "==", doc(db, "jobs", jobId))
      ),
      async (snapshot) => {
        const fetchedConversations = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        const unreadCountsPromises = fetchedConversations.map(
          async (conversation) => {
            const unreadCount = await fetchUnreadMessagesCount(conversation.id);
            return { [conversation.id]: unreadCount };
          }
        );

        const receivedCountsPromises = fetchedConversations.map(
          async (conversation) => {
            const receivedCount = await fetchReceivedMessagesCount(
              conversation.id
            );
            return { [conversation.id]: receivedCount };
          }
        );

        const unreadCountsResults = await Promise.all(unreadCountsPromises);
        const unreadCountsObj = unreadCountsResults.reduce(
          (acc, curr) => ({ ...acc, ...curr }),
          {}
        );
        setUnreadCounts(unreadCountsObj);

        const receivedCountsResults = await Promise.all(receivedCountsPromises);
        const receivedCountsObj = receivedCountsResults.reduce(
          (acc, curr) => ({ ...acc, ...curr }),
          {}
        );
        setTotalReceivedCounts(receivedCountsObj);

        setConversations(fetchedConversations);
      }
    );

    return unsubscribe;
  }, [jobId, jobOwnerId]);

  return {
    unreadCounts,
    totalReceivedCounts,
    loadingConversations: conversations.length === 0,
  };
};

export default useFetchConversationsForJob;
