import classNames from "classnames";
import { useState } from "react";
import { Container, Navbar, Offcanvas } from "react-bootstrap";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { store } from "../app/store.js";
import { logout } from "../app/userRedux/userSlice.js";
import MessagesNotification from "../components/MessagesNotification.jsx";
import Notifications from "../components/Notifications";
import UserDropdown from "../components/UserDropdown";
import { IMAGES } from "../constants/images";
import useLogout from "../hooks/useLogout";
import styles from "./Navbar.module.css";
import { Alert } from "react-bootstrap";
import { useConversation } from "../context/ConversationContext";

const NavBarItem = ({ children, to }) => {
  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        classNames(styles.link, { [styles.activeCandidate]: isActive })
      }
    >
      {children}
    </NavLink>
  );
};

const NavBar = () => {
  const { activeConversationId } = useConversation();
  const { logoutUser } = useLogout();
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(false);
  const [showBanner, setShowBanner] = useState(true);

  const handleMenuOpen = () => {
    setShowMenu(true);
  };

  const handleMenuClose = () => {
    setShowMenu(false);
  };

  const handleItemClick = () => {
    setShowMenu(false); // Close the menu when an item is clicked
  };

  const handleEditUser = () => {
    // Add logic for handling Edit User action
    console.log("Edit User clicked");
  };

  const handleSignOut = async () => {
    logoutUser(null, {
      onSuccess: () => {
        store.dispatch(logout());
        localStorage.clear();
      },
      onError: (error) => {
        console.log("error", error);
      },
    });
  };

  return (
    <>
      <Alert
        dismissible
        show={showBanner}
        onClose={() => setShowBanner(false)}
        className="text-center rounded-none bg-[#46D6EA] text-[#19293D] font-medium min-h-[45px] m-0 p-2 [&>.btn-close]:p-3"
      >
        Welcome to our Beta version! Let us know on{" "}
        <a href="mailto:support@justlawyers.app" className="underline">
          support@justlawyers.app
        </a>{" "}
        if you encounter any issues or have suggestions to improve the platform
        —we appreciate your feedback!
      </Alert>
      <div
        className={styles.navbar}
        style={{
          borderBottom: "5px solid #868BFF",
        }}
      >
        <Link to="/job-board" className={styles.logo}>
          <img
            className="logo_css cursor-pointer"
            src={IMAGES.JLFULL}
            alt="logo"
          />
        </Link>
        <div className={styles.links}>
          <NavBarItem to="/job-board">Job Board</NavBarItem>

          <NavBarItem to="/my-matches">My Overview</NavBarItem>
          <div className="flex flex-row justify-center items-center  ">
            <NavBarItem to="/chat-room">Messages</NavBarItem>
            <MessagesNotification activeConversationId={activeConversationId} />
          </div>

          <NavBarItem to="/me">My Profile</NavBarItem>
          <Notifications />
          <div className="justify-center align-items-center flex mr-2">
            <UserDropdown
              handleEditUser={handleEditUser}
              handleSignout={handleSignOut}
              navigate={navigate}
            />
          </div>
        </div>
      </div>
      <Navbar
        expand="lg"
        className="inline-block custom:hidden bg-body-tertiary mb-3 w-full"
      >
        <Container fluid>
          <Link to="/" className={styles.logo}>
            <img
              className="logo_css cursor-pointer w-40"
              src={IMAGES.JLFULL}
              alt="logo"
            />
          </Link>
          <div className="flex gap-2 ">
            <Notifications />
            <Navbar.Toggle
              aria-controls={`offcanvasNavbar-expand`}
              onClick={handleMenuOpen}
              className="bg-white"
            />
          </div>
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand`}
            aria-labelledby={`offcanvasNavbarLabel-expand`}
            placement="end"
            onHide={handleMenuClose}
            show={showMenu}
            className="w-1/2"
            style={{ width: "50%" }}
          >
            <Offcanvas.Header className="flex justify-center">
              <Offcanvas.Title>
                <Link
                  to="/job-board"
                  className={styles.logo}
                  onClick={handleItemClick}
                >
                  <img
                    className="cursor-pointer h-[25px]"
                    src={IMAGES.JLFULL}
                    alt="logo"
                  />
                </Link>
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className="">
              <div className="flex flex-col justify-center items-center gap-y-3">
                <NavBarItem to="/job-board" onClick={handleItemClick}>
                  <p className="text-xl">Job Board</p>
                  {/* <p className="text-xl">Job Board</p> */}
                </NavBarItem>
                <NavBarItem to="/my-matches" onClick={handleItemClick}>
                  <p className="text-xl">My Overview</p>
                </NavBarItem>
                <NavBarItem to="/chat-room" onClick={handleItemClick}>
                  <p className="text-xl">Messages</p>
                  <MessagesNotification />
                </NavBarItem>
                <NavBarItem to="/me" onClick={handleItemClick}>
                  <p className="text-xl">My Profile</p>
                </NavBarItem>
                <UserDropdown
                  handleEditUser={handleEditUser}
                  handleSignout={handleSignOut}
                  navigate={navigate}
                  type="responsive"
                />
              </div>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </>
  );
};

export default NavBar;
