import { memo, useState } from "react";
import { Button } from "react-bootstrap";
import { FaFilter } from "react-icons/fa";
import FiltersModal from "./modals/FiltersModal";

const SearchBar = memo(({ onClear, onSearch, userData, isFiltered }) => {
  const [isShowModal, setIsShowModal] = useState(false);
  return (
    <>
      <Button
        className="w-full p-[8px]"
        style={{
          backgroundColor: userData.isEmployer ? "#FFC83A" : undefined,
        }}
        onClick={() => setIsShowModal(true)}
      >
        Expand search
        {isFiltered && <FaFilter />}
      </Button>
      <FiltersModal
        show={isShowModal}
        onSearch={onSearch}
        onClear={onClear}
        onHide={setIsShowModal}
        userData={userData}
      />
    </>
  );
});

export default SearchBar;
