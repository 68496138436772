import { Route, Routes } from "react-router-dom";
import GuestGuard from "../components/routing/GuestGuard";
import FirmRegister from "../pages/RecruiterRegister";
import LoginPage from "./auth/Login";
import RegisterPage from "./auth/Register";
import ForgotPasswordPage from "./auth/ForgotPassword";

const AuthenticationApp = () => {
  return (
    <GuestGuard>
      <Routes>
        <Route path="login" element={<LoginPage />} />
        <Route path="register" element={<RegisterPage />} />
        <Route path="forgot-password" element={<ForgotPasswordPage />} />
        <Route path="register/firm" element={<FirmRegister />} />
        <Route path="*" element="404 Page Not Found" />
      </Routes>
    </GuestGuard>
  );
};

export default AuthenticationApp;
