import { useLocation, useNavigate } from "react-router-dom";
import styles from "./CandidateSignupVerify.module.css";

const CandidateSignupVerify = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  return (
    <div className="flex justify-center items-center h-lvh py-[100px] text-black overflow-y-auto">
      <div className={styles.boxcontainer}>
        <div className="flex flex-column items-center w-full">
          <div className="text-center mb-4">
            <h1 className="font-bold text-3xl">Please verify your email</h1>
          </div>
          <div className="text-center">
            <p>
              You are almost there. We sent an email to <br />{" "}
              <strong>{state?.email ?? "test@test.be"}</strong>
            </p>
            <p className="my-3">
              Please check your inbox and click on the link in that email to
              complete your signup.{" "}
            </p>{" "}
            <p className="my-3">
              This might take a few minutes. If the message doesn't show up in
              your inbox it might be in your spam folder.
            </p>
            <div className="text-center mt-4">
              <button
                className="bg-[#46D6EA]  py-2 px-5 rounded-md font-bold border-1 border-[#46D6EA] cursor-pointer hover:bg-transparent"
                onClick={() => navigate("/")}
              >
                Back to home
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CandidateSignupVerify;
