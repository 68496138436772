import { useQuery } from "@tanstack/react-query";
import app from "../../config/axiosConfig";
import qs from "qs";

const getTotalStatistics = async (queryObject: {}) => {
  const query = qs.stringify({
    ...queryObject,
  });
  const response = await app.get(`api/candidates-statistics?${query}`);
  if (response.status < 200 || response.status > 299) {
    throw new Error(
      "Error fetching statistics per job: " + response.statusText
    );
  }
  return response.data;
};

const useTotalStatistics = (queryObject: {}, enabled = false) => {
  const { data, error, isLoading } = useQuery({
    queryKey: ["candidates-statistics", queryObject],
    queryFn: () => getTotalStatistics(queryObject),
    enabled: enabled,
  });

  return {
    totalStatistics: data,
    error,
    isDataLoading: isLoading,
  };
};

export default useTotalStatistics;
