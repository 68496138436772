import { ChevronRight } from "@mui/icons-material";
import { FiChevronLeft } from "react-icons/fi";
import { IMAGES } from "../../constants/images";
import styles from "./CandidateData.module.css";
import Stepper from "react-stepper-horizontal";

const Layout = ({ children, onBack, onNext, onSkip, step }) => {
  return (
    <div className={styles.container}>
      <img src={IMAGES.LOGO_CANDIDATE} className={styles.logo} />
      <div className={styles.boxcontainer}>
        <div className={styles.innercontainer}>
          <div className="pb-8">
            <Stepper
              activeColor="#8186FF"
              completeColor="#8186FF"
              size={30}
              titleTop={2}
              steps={[
                { title: "Profile" },
                { title: "Education" },
                { title: "Experience" },
                { title: "Job Criteria" },
              ]}
              activeStep={step - 1}
            />
          </div>
          {children}
          <div className={styles.footer}>
            <div className={styles.back}>
              {onBack && (
                <button className={styles.buttonback} onClick={onBack}>
                  <FiChevronLeft size={22} />
                  Back
                </button>
              )}
            </div>
            <div className={styles.next}>
              {onSkip && (
                <button className={styles.buttonskip} onClick={onSkip}>
                  Skip this step
                </button>
              )}
              <button className={styles.buttonnext} onClick={onNext}>
                Next <ChevronRight size={22} />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
