import { AiFillPlusCircle } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { DotLoader } from "react-spinners";
import JobCard from "../../components/JobCard";
import useMyJobs from "../../hooks/jobs/useMyJobs";
import styles from "./JobBoard.module.css";
import { TABS_JOB_PAGE } from "./JobPage";

const JobBoardRecruiter = () => {
  const navigate = useNavigate();
  const { myJobs, isMyJobsLoading } = useMyJobs();

  const handleShowJobPage = (job, page) => {
    navigate(`/my-job/${job.uid}`, {
      state: {
        defaultTab: page,
      },
    });
  };

  return (
    <div className={styles.wrapper}>
      <section className={styles.container}>
        <div className={styles.header}>
          <div>
            <h1 className={styles.title}>My Jobs</h1>
            {!isMyJobsLoading && (
              <div className={styles.subtitle}>
                You have {myJobs.length} jobs
              </div>
            )}
          </div>
          <div>
            <Link to="/create-job" className={styles.createCTA}>
              <AiFillPlusCircle size={30} />
              New Job
            </Link>
          </div>
        </div>
        <div className={styles.jobs}>
          {isMyJobsLoading ? (
            <DotLoader color="#868bff" />
          ) : (
            myJobs.map((job) => (
              <div key={job.uid}>
                <JobCard
                  handleShowJobPage={(tab) => handleShowJobPage(job, tab)}
                  jobData={job}
                  onClick={() =>
                    handleShowJobPage(job, TABS_JOB_PAGE.Potential)
                  }
                />
              </div>
            ))
          )}
        </div>
      </section>
    </div>
  );
};

export default JobBoardRecruiter;
