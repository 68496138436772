import { CgFlagAlt } from "react-icons/cg";
import { CiLocationOn } from "react-icons/ci";
import { FaUserGroup } from "react-icons/fa6";
import { VscGlobe } from "react-icons/vsc";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { DotLoader } from "react-spinners";
import Logo from "../../components/firms/Logo";
import RecruiterProfileJobCard from "../../components/RecruiterProfileJobCard.jsx";
import SanitizeString from "../../components/SanitizeString";
import useMyJobs from "../../hooks/jobs/useMyJobs";

const RecruiterProfile = () => {
  const employer = useSelector((state) => state.data.value);
  const { myJobs, isMyJobsLoading } = useMyJobs();

  const firmWebsite = employer.firmWebsite;

  const url = firmWebsite
    ? firmWebsite.startsWith("http://") || firmWebsite.startsWith("https://")
      ? firmWebsite
      : `https://${firmWebsite}`
    : "#";

  return (
    <>
      <div className="flex flex-column items-center text-white overflow-y-auto">
        <div className="flex flex-col md:flex-row my-5 w-[90%] lg:w-[80%] rounded-md gap-6">
          {/* upper part */}
          <section className="flex flex-col h-fit items-center justify-between bg-[#364A63] rounded-md py-8 w-full md:max-w-[400px]">
            <div className="flex flex-col items-center gap-3">
              <div>
                <Logo src={employer?.firmLogo} size="8rem" border />
              </div>
              <h3 className="font-bold text-xl">{employer?.companyName}</h3>
              <div className="flex flex-col justify-center gap-2 items-start">
                <p className="flex items-center mt-2 gap-2 ">
                  <CiLocationOn size={22} />
                  {employer?.firmLocation ? (
                    <span>{employer.firmLocation}</span>
                  ) : (
                    <span>Company location not added</span>
                  )}
                </p>
                <p className="flex items-center mt-2 gap-2 ">
                  <FaUserGroup />
                  {employer?.firmSize ? (
                    <span>{employer.firmSize}</span>
                  ) : (
                    <span>Company size</span>
                  )}
                </p>
                <p className="flex items-center mt-2 gap-2 ">
                  <CgFlagAlt />
                  <span className="text-sm">Founded in</span>
                  {employer?.firmFounded}
                </p>
                <Link
                  to={url}
                  target="_blank"
                  className="flex items-center gap-2"
                >
                  <VscGlobe />
                  Website
                </Link>
              </div>
            </div>
          </section>
          <div className="flex flex-col gap-6 w-full">
            <section className="flex">
              <div className="bg-[#364A63] w-full rounded-md p-4">
                <div>
                  <div className="flex items-center justify-between mb-1">
                    <h3 className="font-bold">About Us</h3>
                    {/* <AiFillEdit size={24} className="cursor-pointer" /> */}
                  </div>
                  <SanitizeString content={employer?.aboutCompany ?? ""} />
                </div>
              </div>
            </section>
            <section className="flex">
              <div className="bg-[#364A63] w-full rounded-md p-4">
                <div>
                  <div className="flex items-center justify-between mb-5">
                    <h3 className="font-bold">Posted Jobs</h3>
                  </div>
                  {isMyJobsLoading ? (
                    <DotLoader color="#868bff" />
                  ) : (
                    myJobs.map((job) => (
                      <div className=" mt-4 h-fit">
                        <RecruiterProfileJobCard key={job.uid} jobData={job} />
                      </div>
                    ))
                  )}
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

export default RecruiterProfile;
