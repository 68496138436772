import { isToday, isYesterday } from "date-fns";
import { useState } from "react";
import { Button } from "react-bootstrap";
import { BiMessage } from "react-icons/bi";
import { CiLocationOn } from "react-icons/ci";
import { MdOutlineMenu } from "react-icons/md";
import { PiSuitcaseLight } from "react-icons/pi";
import { TbArrowBack, TbMessageCircle } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateInitialData } from "../../../app/initialDataSlice.js";
import { IMAGES } from "../../../constants/images.jsx";
import useLikeJob from "../../../hooks/jobs/useLikeJob";
import useToggleRejectJob from "../../../hooks/jobs/useToggleRejectJob";
import useNavigateToConversation from "../../../hooks/useNavigateToConversation";
import ApplicationStatusJobCardHeader from "../../ApplicationStatusJobCardHeader";
import MatchBadge from "../../MatchBadge";
import MatchPercentageBadge from "../../MatchPercentageBadge";
import SanitizeString from "../../SanitizeString";
import Logo from "../../firms/Logo";
import ModalCandidateJobApply from "../../modalCandidateJobApply/modalCandidateJobApply.jsx";
import styles from "./JobCard.module.css";

const JobCard = ({ job, onClick, queryObject, refetchJobs }) => {
  const navigate = useNavigate();
  const initialData = useSelector((state) => state.initialData);
  const { likeJob } = useLikeJob();
  const { toggleRejectJob } = useToggleRejectJob();
  const [isLiked, setIsLiked] = useState(
    job?.savedby?.includes(initialData?.uid)
  );
  const [isRejected, setIsRejected] = useState(
    job?.rejectedby?.includes(initialData.uid)
  );
  const [isMatched, setIsMatched] = useState(
    job?.savedby?.includes(initialData.uid) &&
      initialData.saved.includes(job.uid)
  );
  const [showModal, setShowModal] = useState(false);
  const [applied, setApplied] = useState(
    job?.applicants?.includes(initialData.uid)
  );

  const dispatch = useDispatch();

  const { navigateToConversation } = useNavigateToConversation();

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleShowModal = () => {
    dispatch(updateInitialData({ selectedJob: job }));
    setShowModal(true);
  };

  const goToJob = () => {
    if (onClick) {
      onClick(job.uid);
      return;
    }

    navigate(`/job-details-page/${job.uid}`);
  };

  const formatDate = (dateString) => {
    const formattedDate = new Date(dateString);

    // Check if the date is today or yesterday
    if (isToday(formattedDate)) {
      return "Today";
    } else if (isYesterday(formattedDate)) {
      return "Yesterday";
    } else {
      // If not today or yesterday, display the actual date
      const options = { year: "numeric", month: "long", day: "numeric" };
      return formattedDate.toLocaleDateString(undefined, options);
    }
  };

  return (
    <>
      <div className={styles.container}>
        {isMatched && !applied && <MatchBadge />}
        {job?.applicationData != null && (
          <ApplicationStatusJobCardHeader
            applicationData={job.applicationData}
          />
        )}
        <div className={styles.content}>
          {job?.applicationData?.rejected && (
            <div className="bg-white w-full h-full absolute top-0 left-0 bg-opacity-50 cursor-default" />
          )}
          {/* image and info */}
          <div className="flex flex-col justify-between">
            <div className="flex justify-between">
              <div className="flex items-center">
                <Logo src={job?.firmLogo} size={50} />
                <p
                  className="ml-4 font-medium text-[17px]"
                  style={{
                    display: "-webkit-box",
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                  }}
                >
                  {job?.companyName}
                </p>
              </div>

              {!applied && (
                <Button
                  className="pressable"
                  variant="secondary"
                  onClick={() => {
                    handleShowModal();
                  }}
                >
                  Apply
                </Button>
              )}
            </div>
            <div
              disabled={job?.applicationData?.rejected ?? false}
              onClick={() => {
                if (job?.applicationData?.rejected ?? false) return;
                goToJob();
              }}
              className="flex flex-col"
            >
              <div className="border-b-2 border-gray-300 mt-2 pb-3">
                <h1
                  style={{
                    display: "-webkit-box",
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                  }}
                  className={styles.title}
                >
                  {job.jobTitle}
                </h1>
                <p className="text-xs my-1 mb-4 text-[#131F2E]/50">
                  Created: {formatDate(job?.createdAt)}
                </p>
                <MatchPercentageBadge
                  inline
                  percentage={job.matchedPercentage}
                />
              </div>
              <div className="my-3 flex font-medium flex-col gap-1">
                <div className="flex items-center">
                  <CiLocationOn size={20} className="mr-2" />
                  <span
                    style={{
                      display: "-webkit-box",
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                    }}
                  >
                    {job?.jobCities == "" || job?.jobCities == null
                      ? "Location not disclosed"
                      : job?.jobCities.join(", ")}
                  </span>
                </div>
                <div className="flex items-center">
                  <PiSuitcaseLight size={20} className="mr-2" />
                  <span
                    style={{
                      display: "-webkit-box",
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                    }}
                  >
                    {job?.isStudent ? (
                      <>Internship or student job</>
                    ) : (
                      <>
                        {job?.jobExperienceMin} - {job?.jobExperienceMax} years
                        of experience
                      </>
                    )}
                  </span>
                </div>
                <div className="flex items-center">
                  <BiMessage size={20} className="mr-2" />
                  <span
                    style={{
                      display: "-webkit-box",
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                    }}
                  >
                    {job?.jobLanguages == "" || job?.jobLanguages == null
                      ? "No language provided"
                      : job?.jobLanguages.join(", ")}
                  </span>
                </div>
                <div className="flex items-center ">
                  <MdOutlineMenu size={0} className="mr-2" />
                  <span
                    style={{
                      display: "-webkit-box",
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                    }}
                  >
                    {job?.jobExpertise == "" || job?.jobExpertise == null
                      ? "No expertise specified"
                      : job?.jobExpertise.join(", ")}
                  </span>
                </div>
              </div>
              <div>
                <SanitizeString
                  content={job?.jobDescription ?? ""}
                  className="max-h-[40px] list-disc mb-2 font-normal text-[#131F2E]/70 text-wrap break-normal line-clamp-2"
                />
              </div>
            </div>
          </div>
          <div>
            <Button
              className="md:mt-2 md:hidden pressable w-full"
              variant={"outlined"}
              onClick={() => {
                goToJob();
              }}
            >
              See full job description
            </Button>
            {!applied && !job?.applicants?.includes(initialData.uid) && (
              <div className="cursor-pointer">
                <div className="flex items-center justify-center mt-3 gap-x-3 w-full space-between">
                  {!isLiked && (
                    <Button
                      variant={isRejected ? "reject-active" : "reject"}
                      className="flex-1 pressable"
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleRejectJob({
                          jobId: job.uid,
                          userId: initialData.uid,
                          queryObject: queryObject,
                        });
                        setIsRejected(!isRejected);
                      }}
                    >
                      {isRejected && <TbArrowBack />}
                      {isRejected ? "Undo Not Interested" : "Not Interested"}
                    </Button>
                  )}
                  {!isRejected && (
                    <Button
                      className="flex-1 pressable"
                      variant={isLiked ? "like-active" : "like"}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setIsLiked(!isLiked);
                        likeJob({
                          jobId: job.uid,
                          userId: initialData.uid,
                          jobUserId: job.userid,
                          queryObject: queryObject,
                        });
                      }}
                    >
                      {!isLiked ? (
                        <img
                          src={
                            isLiked
                              ? IMAGES.GREEN_HEART_FILLED
                              : IMAGES.GREEN_HEART
                          }
                          width={15}
                          height={14}
                        />
                      ) : (
                        <TbArrowBack />
                      )}
                      {!isLiked ? "Like" : "Undo Like"}
                    </Button>
                  )}
                </div>
                <div
                  className="flex mt-3 items-center justify-center text-center pressable"
                  onClick={() => {
                    navigateToConversation(job.userid, job.uid);
                  }}
                >
                  <p className="flex items-center mb-1 cursor-pointer text-[#131F2E]/60 pressable">
                    <TbMessageCircle size={20} className="mr-2" />
                    Ask a question
                  </p>
                </div>
              </div>
            )}
            {(applied || job?.applicants?.includes(initialData.uid)) && (
              <div
                disabled={job?.applicationData?.rejected ?? false}
                className="flex mt-3 items-center justify-center text-center pressable"
                onClick={() => {
                  if (job?.applicationData?.rejected ?? false) return;
                  navigateToConversation(job.userid, job.uid);
                }}
              >
                <p className="flex items-center mb-1 cursor-pointer text-[#131F2E]/60 pressable">
                  <TbMessageCircle size={20} className="mr-2" />
                  Start the conversation
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      <ModalCandidateJobApply
        showModal={showModal}
        handleClose={handleCloseModal}
        setApplied={(e) => setApplied(e)}
        job_id={job?.uid}
        refetchJobs={refetchJobs}
      />
    </>
  );
};

export default JobCard;
