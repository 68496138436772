import { useState } from "react";
import Form from "react-bootstrap/Form";
import Collapsible from "react-collapsible";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import Switch from "react-switch";
import { updateInitialData } from "../../app/initialDataSlice";
import { firmsOptions } from "../../constants/Data.js";
import { getTotalYearsOfExperience } from "../../utils/dates";
import styles from "./CandidateData.module.css";
import Layout from "./Layout.jsx";
import anonymousIcon from "../../assets/icons/anonymous.png";

const getLawFirmValue = (lawFirmName) => {
  if (typeof lawFirmName === "object" && lawFirmName !== null) {
    return lawFirmName;
  }
  return lawFirmName ? { value: lawFirmName, label: lawFirmName } : null;
};

function formatEmploymentExperience(employment) {
  const lawfirmName =
    employment.lawfirmname?.label ||
    employment.lawfirmname?.value ||
    employment.lawfirmname;
  const positionTitle = employment.positiontitle;

  if (!lawfirmName && !positionTitle) {
    return "New experience";
  }

  return `${lawfirmName || ""}${lawfirmName && positionTitle ? " - " : ""}${positionTitle || ""}`;
}

const CandidateData2 = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const initialData = useSelector((state) => state.initialData);

  const [noWorkExperience, setNoWorkExperience] = useState(false);
  const [openCollapsible, setOpenCollapsible] = useState(null);

  const handleCollapsibleClick = (id) => {
    setOpenCollapsible((prev) => (prev === id ? null : id));
  };
  const handleUpdateEmploymentItem = (index) => {
    const updatedEmploymentHistory = [...initialData.employmentHistory];

    if (index >= updatedEmploymentHistory.length) {
      updatedEmploymentHistory.push({
        lawfirmname: "",
        positiontitle: "",
        employmentend: "",
        employmentstart: "",
        remuneration: "",
        stillEmployed: false,
        notOnTheList: false,
      });
    }

    dispatch(
      updateInitialData({ employmentHistory: updatedEmploymentHistory })
    );
  };

  const handleDeleteEmploymentItem = (index) => {
    const updatedEmploymentHistory = [...initialData.employmentHistory];

    const newArray = updatedEmploymentHistory.filter(
      (element) => element !== updatedEmploymentHistory[index]
    );

    dispatch(
      updateInitialData({
        employmentHistory: newArray,
        experience: getTotalYearsOfExperience(newArray),
      })
    );
  };

  const updateEmploymentItem = (index, property, newValue) => {
    try {
      const updatedEmploymentHistory = [
        ...initialData.employmentHistory.slice(0, index),
        {
          ...initialData.employmentHistory[index],
          [property]: newValue,
        },
        ...initialData.employmentHistory.slice(index + 1),
      ];

      const ALL_EMPLOYERS = updatedEmploymentHistory.map(
        (firm) => firm.lawfirmname
      );

      dispatch(
        updateInitialData({
          employmentHistory: updatedEmploymentHistory,
          nottomatch: [
            ...new Set(
              [...initialData.nottomatch, ...ALL_EMPLOYERS].filter(Boolean)
            ),
          ],
          experience: getTotalYearsOfExperience(updatedEmploymentHistory),
        })
      );
    } catch (error) {
      console.log("An error occurred:", error);
    }
  };

  const nextWindow = (e) => {
    navigate("/candidatedata4");
  };

  const previousWindow = () => {
    navigate("/candidatedata1");
  };

  return (
    <Layout
      onBack={previousWindow}
      onNext={nextWindow}
      onSkip={nextWindow}
      step={3}
    >
      <section className={styles.section}>
        <h2 className={styles.title}>Experience</h2>
        <p>
          Please provide your current and past law firm work experience(s).
          <br />
          Are you a student? Please share any law firm internships you've
          completed or plan to complete.
        </p>

        {/* ------------------------ Experience Switch ------------------------*/}
        <div className={styles.switchContainer}>
          I don't have any work experience
          <Switch
            onChange={(e) => {
              setNoWorkExperience(e);
              dispatch(
                updateInitialData({
                  employmentHistory: [],

                  experience: 0,
                })
              );
            }}
            checked={noWorkExperience}
            onColor="#86d3ff"
            onHandleColor="#2693e6"
            handleDiameter={15}
            uncheckedIcon={false}
            checkedIcon={false}
            height={15}
            width={40}
          />
        </div>

        {/* ------------------------ Experience List ------------------------*/}
        <div className={styles.experienceList}>
          {!noWorkExperience && (
            <>
              {initialData?.employmentHistory?.map((element, i) => (
                /* ------------------------ Collabsible Example (Make into a component) ------------------------*/
                <div
                  className="flex w-full items-center justify-between"
                  key={i}
                >
                  <Collapsible
                    trigger={
                      <div className="w-100">
                        {formatEmploymentExperience(
                          initialData.employmentHistory[i]
                        )}
                      </div>
                    }
                    open={openCollapsible === `collapsible${i}`}
                    onOpening={() => {
                      handleCollapsibleClick(`collapsible${i}`);
                      handleCollapsibleClick(`collapsible${i + 1}`);
                      handleCollapsibleClick(`collapsible${i - 1}`);
                    }}
                  >
                    {/* ------------------------ Law Firm Name  ------------------------*/}
                    <div className="w-full mt-4">
                      <div className={styles.field}>
                        <label>
                          Law Firm Name
                          <img
                            src={anonymousIcon}
                            className={styles.anonymousIcon}
                          />
                        </label>
                        <p>
                          Select your law firm. Is your law firm not in the
                          list? Add it by typing.
                        </p>
                        <CreatableSelect
                          isClearable
                          placeholder="Select Law Firm..."
                          defaultValue={[]}
                          name="type"
                          options={firmsOptions}
                          className="basic-select"
                          classNamePrefix="select"
                          onChange={(selectedOption) =>
                            updateEmploymentItem(
                              i,
                              "lawfirmname",
                              selectedOption ? selectedOption.value : ""
                            )
                          }
                          value={getLawFirmValue(
                            initialData.employmentHistory[i].lawfirmname
                          )}
                        />
                      </div>
                    </div>
                    <div className={styles.field}>
                      <label>Position title</label>
                      <input
                        className={styles.input}
                        placeholder="Enter your Position"
                        onChange={(e) =>
                          updateEmploymentItem(
                            i,
                            "positiontitle",
                            e.target.value
                          )
                        }
                        value={initialData.employmentHistory[i].positiontitle}
                      />
                    </div>

                    <div className={styles.field}>
                      <label>Employment Start Date</label>
                      <Form.Control
                        type="date"
                        className={styles.input}
                        placeholder="Select Date"
                        onChange={(e) =>
                          updateEmploymentItem(
                            i,
                            "employmentstart",
                            e.target.value
                          )
                        }
                        value={initialData.employmentHistory[i].employmentstart}
                      />
                    </div>

                    <div className={styles.field}>
                      <label>Employment End Date</label>

                      <div className={styles.switchContainer}>
                        Still Employed
                        <Form.Check // prettier-ignore
                          type="switch"
                          id="custom-switch"
                          onChange={(e) =>
                            updateEmploymentItem(
                              i,
                              "stillEmployed",
                              e.target.checked
                            )
                          }
                          checked={
                            initialData.employmentHistory[i].stillEmployed
                          }
                        />
                      </div>

                      <Form.Control
                        type="date"
                        disabled={
                          initialData.employmentHistory[i].stillEmployed
                            ? true
                            : false
                        }
                        className={styles.input}
                        placeholder="Select Date"
                        onChange={(e) =>
                          updateEmploymentItem(
                            i,
                            "employmentend",
                            e.target.value
                          )
                        }
                        value={initialData.employmentHistory[i].employmentend}
                      />
                    </div>
                  </Collapsible>
                  <RiDeleteBin6Line
                    size={24}
                    className="ml-2 cursor-pointer "
                    onClick={() => handleDeleteEmploymentItem(i)}
                  />
                </div>
              ))}

              {/* ------------------------ Add Experience Button  ------------------------*/}

              <div
                className={styles.buttonaddexp}
                onClick={() =>
                  handleUpdateEmploymentItem(
                    initialData.employmentHistory.length
                  )
                }
              >
                <b>Add Past Experience...</b>
                <b style={{ fontSize: 24 }}>+</b>
              </div>
            </>
          )}
        </div>
      </section>
    </Layout>
  );
};

export default CandidateData2;
