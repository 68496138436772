import { createContext, useState, ReactNode, useContext } from "react";

interface ConversationContextType {
  activeConversationId: string | null;
  setActiveConversationId: (id: string | null) => void;
}

const ConversationContext = createContext<ConversationContextType | undefined>(
  undefined
);

interface ConversationProviderProps {
  children: ReactNode;
}

export const ConversationProvider: React.FC<ConversationProviderProps> = ({
  children,
}) => {
  const [activeConversationId, setActiveConversationId] = useState<
    string | null
  >(null);

  return (
    <ConversationContext.Provider
      value={{
        activeConversationId,
        setActiveConversationId,
      }}
    >
      {children}
    </ConversationContext.Provider>
  );
};

export const useConversation = (): ConversationContextType => {
  const context = useContext(ConversationContext);
  if (context === undefined) {
    throw new Error(
      "useConversation must be used within a ConversationProvider"
    );
  }
  return context;
};
