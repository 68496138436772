import { formatDistance } from "date-fns";
import { doc, updateDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { CANDIDATE_TYPES, JOB_TYPES, NOTIFICATION_TYPES } from ".";
import { db } from "../../config/firebase.js";
import useCandidate from "../../hooks/candidates/useCandidate";
import useJob from "../../hooks/jobs/useJob";
import { TABS_JOB_PAGE } from "../../pages/JobBoardRecruiter/JobPage.jsx";
import styles from "./Notifications.module.css";
import classNames from "classnames";
import Dropdown from "react-bootstrap/Dropdown";

export default function NotificationComponent({ notification }) {
  const navigate = useNavigate();
  const { reference, type, read, jobId, date } = notification;

  const { job, isJobLoading } = useJob(jobId, CANDIDATE_TYPES.includes(type));
  const { candidate, isCandidateLoading } = useCandidate(
    reference,
    {},
    JOB_TYPES.includes(type)
  );

  const companyName = job?.companyName || "";
  const jobTitle = job?.jobTitle || "";
  const userName = candidate?.name || "";

  const markNotificationAsRead = async (notificationId) => {
    try {
      const notificationDocument = doc(db, "notifications", notificationId);
      await updateDoc(notificationDocument, { read: true });
    } catch (error) {
      console.error("Error marking notification as read:", error);
    }
  };

  const handleClick = () => {
    if (notification.type === NOTIFICATION_TYPES.APPLY_JOB) {
      navigate(`/my-job/${notification.reference}`, {
        state: { defaultTab: TABS_JOB_PAGE.Applicants },
      });
    } else if (
      notification.type === NOTIFICATION_TYPES.MATCH_JOB ||
      notification.type === NOTIFICATION_TYPES.LIKE_JOB
    ) {
      navigate(
        `/candidate-preview/${notification.reference}/${notification.jobId}`
      );
    } else {
      navigate(`/job-details-page/${notification.reference}`);
    }

    markNotificationAsRead(notification.notificationId);
  };

  const renderNotificationText = () => {
    switch (type) {
      case NOTIFICATION_TYPES.LIKE_CANDIDATE:
        return (
          <>
            <strong>{companyName}</strong> likes your profile for position{" "}
            <strong>{jobTitle}</strong>
          </>
        );
      case NOTIFICATION_TYPES.LIKE_JOB:
        return (
          <>
            <strong>{userName}</strong> liked your position{" "}
            <strong>{jobTitle}</strong>
          </>
        );
      case NOTIFICATION_TYPES.MATCH_JOB:
        return (
          <>
            You have a new match with <strong>{userName}</strong> for position{" "}
            <strong>{jobTitle}</strong>
          </>
        );
      case NOTIFICATION_TYPES.MATCH_CANDIDATE:
        return (
          <>
            You have a new match with <strong>{companyName}</strong> for
            position <strong>{jobTitle}</strong>
          </>
        );
      case NOTIFICATION_TYPES.REJECT_CANDIDATE:
        return (
          <>
            Your application for position <strong>{jobTitle}</strong> at{" "}
            <strong>{companyName}</strong> has been rejected
          </>
        );
      case NOTIFICATION_TYPES.ACCEPT_CANDIDATE:
        return (
          <>
            Your application for position <strong>{jobTitle}</strong> at{" "}
            <strong>{companyName}</strong> has been accepted
          </>
        );
      case NOTIFICATION_TYPES.APPLY_JOB:
        return (
          <>
            You have a new application for position <strong>{jobTitle}</strong>
          </>
        );
      default:
        return "";
    }
  };

  return (
    <Dropdown.Item
      className={classNames(styles.item, {
        [styles.isUnread]: !notification.read,
      })}
    >
      <div onClick={handleClick}>
        {isJobLoading || isCandidateLoading ? (
          <>Loading...</>
        ) : (
          <>
            <div className={styles.itemTitle}>{renderNotificationText()}</div>
            <div className={styles.itemDate}>
              {formatDistance(date, new Date(), { addSuffix: true })}
            </div>
          </>
        )}
      </div>
    </Dropdown.Item>
  );
}
