import { useState } from "react";
import { IoChevronDownSharp } from "react-icons/io5";
import { useSelector } from "react-redux";
import Logo from "./firms/Logo";
import ModalRecuriterEditProfile from "./modalEmployerEditProfile/ModalRecuriterEditProfile";
import ChangePasswordModal from "./modals/ChangePasswordModal.jsx";

const FAQ_PAGE_URL = import.meta.env.VITE_FAQ_PAGE;

const RecruiterDropDown = ({ handleSignout, type }) => {
  const user = useSelector((state) => state.data.value);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);

  const handleMouseEnter = () => {
    setShowDropdown(true);
  };

  const handleMouseLeave = () => {
    setShowDropdown(false);
  };

  const handleShowEditModal = () => {
    setShowEditModal(true);
  };

  const handleCloseEditModal = () => {
    setShowEditModal(false);
  };

  const handleShowChangePasswordModal = () => {
    setShowChangePasswordModal(true);
  };

  const handleCloseChangePasswordModal = () => {
    setShowChangePasswordModal(false);
  };

  return (
    <>
      {type !== "responsive" ? (
        <div
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          style={{ position: "relative", display: "inline-block" }}
        >
          <button
            style={{
              backgroundColor: "transparent",
              border: "none",
              cursor: "pointer",
            }}
            className="flex items-center"
          >
            <Logo src={user?.firmLogo} size="2.5rem" border />
            <IoChevronDownSharp />
          </button>

          {showDropdown && (
            <div
              style={{
                position: "absolute",
                top: "100%",
                right: 0,
                minWidth: "160px",
                backgroundColor: "#fff",
                border: "1px solid #ccc",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                zIndex: 1,
              }}
            >
              <div
                onClick={handleShowEditModal}
                style={{ padding: "8px", cursor: "pointer" }}
              >
                Edit User
              </div>
              <div
                onClick={handleShowChangePasswordModal}
                style={{ padding: "8px", cursor: "pointer" }}
              >
                Change password
              </div>
              <a
                href={FAQ_PAGE_URL}
                target="_blank"
                style={{ padding: "8px", cursor: "pointer" }}
              >
                FAQ
              </a>
              <div
                onClick={handleSignout}
                style={{ padding: "8px", cursor: "pointer" }}
              >
                Signout
              </div>
            </div>
          )}
        </div>
      ) : (
        <div
          style={{
            bottom: 30,
            position: "absolute",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div
            onClick={handleShowEditModal}
            style={{ padding: "8px", cursor: "pointer" }}
          >
            Edit profile
          </div>
          <div
            onClick={handleShowChangePasswordModal}
            style={{ padding: "8px", cursor: "pointer" }}
          >
            Change password
          </div>
          <a
            href={FAQ_PAGE_URL}
            target="_blank"
            style={{ padding: "8px", cursor: "pointer" }}
          >
            FAQ
          </a>
          <div
            onClick={handleSignout}
            style={{ padding: "8px", cursor: "pointer" }}
          >
            Signout
          </div>
          <Logo src={user?.firmLogo} size="2.5rem" border />
        </div>
      )}
      <ModalRecuriterEditProfile
        show={showEditModal}
        handleClose={handleCloseEditModal}
      />
      <ChangePasswordModal
        show={showChangePasswordModal}
        onHide={handleCloseChangePasswordModal}
      />
    </>
  );
};

export default RecruiterDropDown;
