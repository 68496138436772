import Dropdown from "react-bootstrap/Dropdown";
import { PiDotsThreeVerticalBold } from "react-icons/pi";
import { E_JOB_STATUS } from "../../constants/enums";
import styles from "./JobCardDropdown.module.css";

interface IJobCardDropdownProps {
  handleEdit: () => void;
  handleStatusChange: (status: E_JOB_STATUS) => void;
  handleDelete: () => void;
  isActive: boolean;
  isArchived: boolean;
  isFilled: boolean;
}

function JobCardDropdown(props: IJobCardDropdownProps) {
  const {
    handleEdit,
    handleStatusChange,
    handleDelete,
    isActive,
    isArchived,
    isFilled,
  } = props;

  return (
    <Dropdown>
      <Dropdown.Toggle variant="outline" className={styles.dropdownToggle}>
        <PiDotsThreeVerticalBold size={30} className={styles.icon} />
      </Dropdown.Toggle>
      <Dropdown.Menu className={styles.dropdownMenu}>
        <Dropdown.Item disabled={isFilled || isArchived} onClick={handleEdit}>
          Edit
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item
          disabled={isFilled}
          onClick={() => {
            handleStatusChange(
              isActive ? E_JOB_STATUS.draft : E_JOB_STATUS.active
            );
          }}
        >
          {isActive ? "Move to draft" : "Publish"}
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item onClick={handleDelete}>Delete</Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item
          onClick={() => {
            handleStatusChange(
              isArchived ? E_JOB_STATUS.draft : E_JOB_STATUS.archive
            );
          }}
        >
          {isArchived ? "Unarchive" : "Archive"}
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item
          onClick={() => {
            handleStatusChange(
              isFilled ? E_JOB_STATUS.active : E_JOB_STATUS.filled
            );
          }}
        >
          {isFilled ? "Reopen" : "Filled"}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default JobCardDropdown;
