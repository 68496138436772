import { PiCaretCircleLeftFill } from "react-icons/pi";
import { Link } from "react-router-dom";
import { DotLoader } from "react-spinners";
// @ts-ignore
import { IMAGES } from "../../../constants/images";
import styles from "./ForgotPassword.module.css";
import useResetPassword from "../../../hooks/useResetPassword";
import { useForm } from "react-hook-form";
import { Alert } from "react-bootstrap";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const LANDING_PAGE = import.meta.env.VITE_LANDING_PAGE;

const validationSchema = yup
  .object()
  .shape({
    email: yup.string().email().required("Please enter an email"),
  })
  .required();

const ForgotPasswordPage = () => {
  const { data, resetPassword, isLoading, error } = useResetPassword();

  const methods = useForm({
    defaultValues: {
      email: "",
    },
    resolver: yupResolver(validationSchema),
  });

  const {
    handleSubmit,
    register,
    formState: { isValid, isDirty },
  } = methods;

  const submitForm = async (data: { email: string }) => {
    resetPassword(data.email, {});
  };

  return (
    <div className={styles.container}>
      <div className={styles.boxcontainerBtn}>
        <Link to={LANDING_PAGE} className="flex items-center text-white">
          <PiCaretCircleLeftFill
            size={38}
            className="mr-2 text-blue-400 cursor-pointer duration-75 hover:scale-110"
          />
          <p className=""> Back to landing page</p>
        </Link>
      </div>
      <div className={styles.boxcontainer}>
        <img
          src={IMAGES.JLFULL}
          className="w-18 h-10 mb-0"
          alt="JustLawyers logo"
        />

        <div className={styles.innercontainer}>
          {data != null ? (
            <div>
              <p>
                We have received your email successfully. Keep an eye on your
                mailbox.
              </p>
            </div>
          ) : (
            <>
              <div className={styles.littletittle}>
                <p>Forgot password</p>
              </div>
              {error && (
                <Alert variant="danger">
                  <p>{error.message}</p>
                </Alert>
              )}
              <form onSubmit={handleSubmit(submitForm)}>
                <input
                  className={styles.input}
                  type="email"
                  placeholder="Email"
                  {...register("email")}
                />
                <button
                  className={styles.button}
                  disabled={!isDirty || !isValid}
                >
                  {isLoading ? (
                    <DotLoader
                      color={"#ffffff"}
                      loading={isLoading}
                      size={20}
                    />
                  ) : (
                    "Reset password"
                  )}
                </button>
              </form>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordPage;
