import classNames from "classnames";
import { IMAGES } from "../../../constants/images";
import styles from "./Logo.module.css";

const Logo = ({ border = false, shadow = false, size, src }) => {
  return (
    <img
      style={{ width: size, height: size }}
      alt="firm logo"
      src={src ?? IMAGES.FIRM_LOGO}
      className={classNames(styles.logo, {
        [styles.border]: border,
        [styles.shadow]: shadow,
      })}
    />
  );
};

export default Logo;
