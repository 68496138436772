import { useQuery } from "@tanstack/react-query";
import app from "../../config/axiosConfig";

const getStatisticsPerJob = async (jobId: string) => {
  const response = await app.get(`api/candidates-statistics/${jobId}`);
  if (response.status < 200 || response.status > 299) {
    throw new Error(
      "Error fetching statistics per job: " + response.statusText
    );
  }
  return response.data;
};

const useStatisticsPerJob = (jobId: string, enabled = true) => {
  const { data, error, isLoading } = useQuery({
    queryKey: ["candidates", { type: "job-stats", id: jobId }],
    queryFn: () => getStatisticsPerJob(jobId),
    enabled: enabled,
  });

  return {
    statisticsPerJob: data,
    error,
    isDataLoading: isLoading,
  };
};

export default useStatisticsPerJob;
