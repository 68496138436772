import { intervalToDuration, isAfter, isEqual } from "date-fns";
import { useMemo } from "react";
import { v4 as uuidv4 } from "uuid";

import { IMAGES } from "../../constants/images";
import styles from "./ExperienceList.module.css";

const ExperienceList = ({ experiences = [], isIncognito = true }) => {
  const sortedExperiences = useMemo(
    () =>
      [...experiences]
        .sort((a, b) => {
          const startDateA =
            a.employmentstart === "" ? null : new Date(a.employmentstart);
          const startDateB =
            b.employmentstart === "" ? null : new Date(b.employmentstart);

          if (isEqual(startDateA, startDateB)) {
            const endDateA =
              a.employmentend === "" ? new Date() : new Date(a.employmentend);
            const endDateB =
              b.employmentend === "" ? new Date() : new Date(b.employmentend);

            if (isEqual(endDateA, endDateB)) {
              return 0;
            }

            return isAfter(endDateA, endDateB) ? -1 : 1;
          }

          return isAfter(startDateA, startDateB) ? -1 : 1;
        })
        .map((experience) => ({ id: uuidv4(), ...experience })),
    [experiences]
  );

  if (experiences.length === 0) {
    return (
      <div className={styles.text}>This candidate has no experience yet</div>
    );
  }

  return (
    <div className={styles.container}>
      {sortedExperiences.map((experience) => {
        const startDate = experience.employmentstart
          ? new Date(experience.employmentstart)
          : null;
        const endDate = experience.employmentend
          ? new Date(experience.employmentend)
          : null;

        const length = intervalToDuration({
          start: startDate ?? new Date(),
          end: endDate ?? new Date(),
        });

        return (
          <div className={styles.item} key={experience.id}>
            {experience.positiontitle && (
              <div className={styles.title}>{experience.positiontitle}</div>
            )}
            {experience.lawfirmname && (
              <div className={styles.firm}>
                {typeof experience.lawfirmname === "string"
                  ? experience.lawfirmname
                  : experience.lawfirmname.label}
              </div>
            )}
            {startDate && (
              <div className="flex items-center gap-2">
                {isIncognito && <img src={IMAGES.LOCK} alt="Incognito" />}
                <div className={styles.length}>
                  {!length.years && !length.months && (
                    <>Started less than a month ago</>
                  )}
                  {length.years && (
                    <>
                      - {length.years} {length.years === 1 ? "year" : "years"}
                    </>
                  )}{" "}
                  {length.months && (
                    <>
                      {length.months} {length.months === 1 ? "month" : "months"}
                    </>
                  )}
                </div>
                {experience.employmentstart !== "" && (
                  <div className={styles.dates}>
                    {experience.employmentstart} to{" "}
                    {experience.employmentend || "present"}
                  </div>
                )}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default ExperienceList;
