//@ts-ignore TODO: Fix this
import { skillOptions } from "../constants/Data.js";
import { EExpertises, TSkillOption } from "../types/candidate.types";

export const getSkillsForExpertises = (
  expertises: EExpertises[]
): TSkillOption[] => {
  return expertises.reduce<TSkillOption[]>((all, expertise) => {
    if (!skillOptions[expertise]) return all;
    return [...all, ...skillOptions[expertise]];
  }, [] as TSkillOption[]);
};
