import MultiRangeSlider from "multi-range-slider-react";
import { useState } from "react";
import { Alert, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";
import "react-quill/dist/quill.snow.css"; // Import the styles for the editor
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import {
  belgiumCitiesOptions,
  languagesOptions,
  skillOptions,
} from "../../constants/Data.js";
import { IMAGES } from "../../constants/images.jsx";
import { getSkillsForExpertises } from "../../utils/skills.utils";
import { SkillsInputFormEnabled } from "../inputs/formEnabled/SkillsInputFormEnabled";

const Content1 = ({ control, errors, register, watch }) => {
  const { setValue } = useFormContext();

  const [
    jobExperience,
    jobBudget,
    jobExpertise,
    jobSkills,
    budgetDoNotDisclose,
    isStudent,
  ] = watch([
    "jobExperience",
    "jobBudget",
    "jobExpertise",
    "jobSkills",
    "budgetDoNotDisclose",
    "isStudent",
  ]);

  const [skillsOptions, setSkillsOptions] = useState(
    Object.keys(skillOptions)
      .map((key) => ({
        value: key,
        label: key,
      }))
      .filter((expertiseOption) => jobExpertise.includes(expertiseOption.value))
      .flatMap((option) => skillOptions[option.label] || [])
  );

  const handleSelectionExpertise = (selectedOptions, field) => {
    const arrayOfLabels = selectedOptions.map((obj) => obj.label);

    const updatedSkillsOptions = selectedOptions.flatMap(
      (option) => skillOptions[option.label] || []
    );

    const updatedSelectedSkills =
      jobSkills.filter((skill) =>
        updatedSkillsOptions.some((option) => option.value === skill)
      ) || [];

    field.onChange(arrayOfLabels);
    setSkillsOptions(updatedSkillsOptions);
    setValue("jobSkills", updatedSelectedSkills);
  };

  const handleSelectionSkills = (selectedSkills, field) => {
    const arrayOfLabels = selectedSkills.map((obj) => obj.label);
    field.onChange(arrayOfLabels);
  };

  return (
    <div className="flex flex-column  bg-white  rounded-md w-full p-2 ">
      <div className="flex flex-column items-start my-3 px-4">
        <p className="font-bold">Add a New Job</p>
        <p>Please specify the details of the open position</p>
        {/* creation form */}
        <div className="w-100">
          {/* Title */}
          <Form.Group className="flex flex-column w-100">
            <Form.Label className="font-bold">
              Title of the position*
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Job Title"
              className="mb-2"
              {...register("jobTitle")}
            />
            {errors.jobTitle && (
              <p className="text-red-600">{errors.jobTitle.message}</p>
            )}
          </Form.Group>

          {/* City */}
          <div className="flex flex-column w-100">
            <p className="font-bold">City*</p>
            <div className="w-full mb-2">
              <Controller
                name="jobCities"
                control={control}
                render={({ field }) => {
                  const selectedOptions = belgiumCitiesOptions.filter(
                    (cityOption) => field.value.includes(cityOption.value)
                  );
                  return (
                    <CreatableSelect
                      {...field}
                      onChange={(values) => {
                        field.onChange(values.map((value) => value.value));
                      }}
                      value={selectedOptions}
                      isClearable
                      isMulti
                      options={belgiumCitiesOptions}
                      className="basic-multi-select"
                      classNamePrefix="select"
                    />
                  );
                }}
              />
              {errors.jobCities && (
                <p className="text-red-600">{errors.jobCities.message}</p>
              )}
            </div>
          </div>
          {/* Languages */}
          <div className="flex flex-column w-100">
            <p className="font-bold flex items-center gap-1">
              Required Languages*
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip placement="top" className="in" id="tooltip-top">
                    Select only required native / fluent languages
                  </Tooltip>
                }
              >
                <img src={IMAGES.QUESTIONMARK} height={17.5} width={17.5} />
              </OverlayTrigger>
            </p>
            <div className="w-full mb-2">
              <Controller
                name="jobLanguages"
                control={control}
                render={({ field }) => {
                  const selectedOptions = languagesOptions.filter(
                    (languageOption) =>
                      field.value.includes(languageOption.value)
                  );

                  return (
                    <CreatableSelect
                      {...field}
                      onChange={(values) => {
                        field.onChange(values.map((value) => value.value));
                      }}
                      value={selectedOptions}
                      isClearable
                      isMulti
                      options={languagesOptions}
                      className="basic-multi-select"
                      classNamePrefix="select"
                    />
                  );
                }}
              />
              {errors.jobLanguages && (
                <p className="text-red-600">{errors.jobLanguages.message}</p>
              )}
            </div>
          </div>

          {/* Expertise */}
          <div>
            <div className=" my-2">
              <p className="font-bold">Expertise*</p>
              <Controller
                name="jobExpertise"
                control={control}
                render={({ field }) => {
                  const selectedOptions = Object.keys(skillOptions)
                    .map((key) => ({
                      value: key,
                      label: key,
                    }))
                    .filter((expertiseOption) =>
                      field.value?.includes(expertiseOption.value)
                    );

                  return (
                    <Select
                      {...field}
                      onChange={(values) =>
                        handleSelectionExpertise(values, field)
                      }
                      value={selectedOptions}
                      isClearable
                      isMulti
                      options={Object.keys(skillOptions).map((key) => ({
                        value: key,
                        label: key,
                      }))}
                      className="basic-multi-select"
                      classNamePrefix="select"
                    />
                  );
                }}
              />
              {errors.jobExpertise && (
                <p className="text-red-600">{errors.jobExpertise.message}</p>
              )}
            </div>
          </div>
          {/* Skills */}
          <SkillsInputFormEnabled
            fieldName="jobSkills"
            control={control}
            handleSelectionSkills={handleSelectionSkills}
            options={getSkillsForExpertises(jobExpertise)}
          />
          {/* required experience */}
          <Form.Group className="flex flex-column w-100 mt-2">
            <p className="font-bold mb-2">Years of experience *</p>
            <div className="flex flex-col md:flex-row items-start md:items-center justify-between">
              <Controller
                name="isStudent"
                control={control}
                render={({ field }) => {
                  return (
                    <Form.Check
                      {...field}
                      {...register("isStudent")}
                      type="switch"
                      name="isStudent"
                      id="custom-switch"
                      label="looking for a summer intern or law graduate for a bar traineeship"
                      onChange={(e) => {
                        field.onChange(e.target.checked);
                        if (e.target.checked) {
                          setValue("jobExperience", { min: 0, max: 1 });
                        }
                      }}
                    />
                  );
                }}
              />
            </div>
            <div>
              <Controller
                name="jobExperience"
                control={control}
                render={({ field: { onChange, value, ...field } }) => {
                  const handleChange = (value) => {
                    onChange({ min: value.minValue, max: value.maxValue });
                  };

                  return (
                    <MultiRangeSlider
                      {...field}
                      onChange={handleChange}
                      min={0}
                      max={7}
                      step={1}
                      label={false}
                      ruler={false}
                      minValue={value.min}
                      maxValue={value.max}
                      className="border-none shadow-none z-0"
                      disabled={isStudent}
                    />
                  );
                }}
              />
              <div className="flex items-center justify-between">
                <p>
                  Minimum <span className="font-bold">{jobExperience.min}</span>{" "}
                  years of experience
                </p>
                <p>
                  Maximum <span className="font-bold">{jobExperience.max}</span>{" "}
                  years of experience
                </p>
              </div>
              {errors.jobExperience && (
                <p className="text-red-600">{errors.jobExperience.message}</p>
              )}
            </div>
          </Form.Group>

          {/* Budget */}
          <Form.Group className="flex flex-column w-100 mt-2">
            <p className="font-bold mb-2">Budget*</p>
            <Alert>
              The budget will not be disclosed in the job description; it is
              solely used for optimizing our matchmaking algorithm.
            </Alert>
            <Form.Check
              type="switch"
              id="custom-switch"
              label="Prefer not to disclose"
              {...register("budgetDoNotDisclose")}
            />
            <div>
              <Controller
                name="jobBudget"
                control={control}
                render={({ field: { onChange, value } }) => {
                  const handleChange = (value) => {
                    onChange({ min: value.minValue, max: value.maxValue });
                  };

                  return (
                    <MultiRangeSlider
                      onChange={handleChange}
                      min={0}
                      max={250}
                      step={10}
                      stepOnly
                      label={false}
                      ruler={false}
                      minValue={value.min}
                      maxValue={value.max}
                      className="border-none shadow-none z-0"
                      disabled={budgetDoNotDisclose}
                    />
                  );
                }}
              />
              <div className="flex items-center justify-between">
                <p className="text-xs">
                  Minimum Budget{" "}
                  <span className="font-bold">€{jobBudget.min}K</span>
                </p>
                <p className="text-xs">
                  Maximum Budget{" "}
                  <span className="font-bold">€{jobBudget.max}K</span>
                </p>
              </div>
            </div>
          </Form.Group>
        </div>
      </div>
    </div>
  );
};

export default Content1;
