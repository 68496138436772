import { useMutation } from "@tanstack/react-query";
// @ts-ignore
import app from "../config/axiosConfig";

const _updatePassword = async (newPassword: string) => {
  try {
    const response = await app.post(`/api/update-password`, {
      newPassword: newPassword,
    });

    if (response.status < 200 || response.status > 299) {
      throw new Error(response.data.error);
    }

    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};
const useUpdatePassword = () => {
  const {
    data,
    error,
    isPending: isLoading,
    mutate: updatePassword,
  } = useMutation({
    mutationFn: _updatePassword,
  });

  return { data, error, isLoading, updatePassword };
};

export default useUpdatePassword;
