import { useMutation, useQueryClient } from "@tanstack/react-query";
import app from "../../config/axiosConfig";

const _toggleRejectJob = async ({
  jobId,
  userId,
  queryObject = {},
}: {
  jobId: string;
  userId: string;
  queryObject?: any;
}) => {
  const response = await app.put(`api/toggle-reject-job`, {
    jobId: jobId,
    userId: userId,
  });
  if (response.status < 200 || response.status > 299) {
    throw new Error("Error toggling reject job: " + response.statusText);
  }
  return response.data;
};

const useToggleRejectJob = () => {
  const queryClient = useQueryClient();

  const { isPending: isUpdating, mutate: toggleRejectJob } = useMutation({
    mutationFn: _toggleRejectJob,
    onMutate: async (data) => {
      let queryKey = ["jobs", data.queryObject];

      if (data.queryObject == null) {
        queryKey = ["jobs"];
      }

      await queryClient.cancelQueries({ queryKey });

      const previousJobs: any[] = queryClient.getQueryData(queryKey) ?? [];

      const job = previousJobs?.find((job: any) => job?.uid === data.jobId);

      if (job == null) return { previousJobs };

      const updatedJobs = previousJobs.filter(
        (job: any) => job?.uid !== data.jobId
      );

      queryClient.setQueryData(["jobs", data.queryObject], updatedJobs);

      return { previousJobs };
    },
    onError: (error, data, context) => {
      queryClient.setQueryData(
        ["jobs", data.queryObject],
        context?.previousJobs
      );
    },

    onSettled: () => {
      if (queryClient.isMutating({ mutationKey: ["jobs"] }) === 1) {
        queryClient.invalidateQueries({ queryKey: ["jobs"] });
      }
      queryClient.invalidateQueries({
        queryKey: ["jobs", { type: "my-matches-stats" }],
      });
    },
  });

  return {
    isLoading: isUpdating,
    toggleRejectJob,
  };
};

export default useToggleRejectJob;
