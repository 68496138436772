import CreatableSelect from "react-select/creatable";
import { ESkills } from "../../types/candidate.types";
import { IOption } from "../../types/general.types";

interface IProps {
  title?: string;
  subtitle: string;
  placeholder?: string;
  handleSelectionSkills: (values: any, field: any) => void;
  options: IOption<ESkills>[];
  values: ESkills[];
  name: string;
  wrapperClassName?: string;
  defaultValues?: ESkills[];
  isClearable?: boolean;
}

const getSelectedValuesFromOptions = (
  options: IOption<ESkills>[],
  selectedValues: ESkills[] = []
) => {
  return selectedValues.reduce((all, value) => {
    const existingOption = options.find((option) => option.value === value);
    const optionToAdd = existingOption ?? { value, label: value.toString() };

    all.push(optionToAdd);

    return all;
  }, [] as IOption<ESkills>[]);
};

export const SkillsInput = ({
  title = "Skills",
  subtitle,
  placeholder = "Select or type your skills",
  wrapperClassName,
  options,
  values,
  name,
  defaultValues = [],
  isClearable = true,
  handleSelectionSkills,
}: IProps) => {
  return (
    <div className={wrapperClassName}>
      <label>{title}</label>
      <p>{subtitle}</p>
      <CreatableSelect
        placeholder={placeholder}
        defaultValue={getSelectedValuesFromOptions(options, defaultValues)}
        isMulti
        isClearable={isClearable}
        name={name}
        // @ts-ignore TODO: Fix this
        options={options}
        className="basic-multi-select"
        classNamePrefix="select"
        onChange={handleSelectionSkills}
        value={getSelectedValuesFromOptions(options, values)}
      />
    </div>
  );
};
