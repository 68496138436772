import { collection, getDocs, query } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { db } from "../config/firebase";
import useFetchConversations from "../hooks/useFetchConversations.js";

export default function MessagesNotification(activeConversationId) {
  const user = useSelector((state) => state.user.userData);
  const [unReadMessageCount, setUnreadMessageCount] = useState(0);
  const { conversations, loadingConversations } = useFetchConversations();

  useEffect(() => {
    if (!loadingConversations) {
      // Collect all queries to run once
      const messageQueries = conversations.map((conversation) =>
        query(collection(db, "conversations", conversation.id, "messages"))
      );

      // Run all queries at once
      Promise.all(messageQueries.map((query) => getDocs(query)))
        .then((querySnapshots) => {
          let totalUnreadCount = 0;
          querySnapshots.forEach((querySnapshot) => {
            const unreadMessagesCount = querySnapshot.docs
              .map((doc) => doc.data())
              .filter(
                (message) => !message.read && message.sentBy !== user?.uid
              ).length;
            totalUnreadCount += unreadMessagesCount;
          });
          setUnreadMessageCount(totalUnreadCount);
        })
        .catch((error) => {
          console.error("Error fetching messages:", error);
        });
    }
  }, [
    conversations,
    loadingConversations,
    user,
    setUnreadMessageCount,
    activeConversationId,
  ]);

  return (
    unReadMessageCount !== 0 && (
      <div
        className="flex justify-center items-center bg-[#868BFF] rounded-full"
        style={{
          alignItems: "center",
          width: 15,
          height: 15,
          fontSize: 10,
          bottom: 5,
          position: "relative",
        }}
      >
        <p className=" text-white">{unReadMessageCount}</p>
      </div>
    )
  );
}
