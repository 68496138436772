import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { GlobalModalsProvider } from "./GlobalModalsContext";
import { ConversationProvider } from "./ConversationContext";

type Props = {
  children?: React.ReactNode;
};

export const queryClient = new QueryClient();

const Providers = ({ children }: Props) => {
  return (
    <QueryClientProvider client={queryClient}>
      <ConversationProvider>
        <GlobalModalsProvider>{children}</GlobalModalsProvider>
      </ConversationProvider>
    </QueryClientProvider>
  );
};

export default Providers;
