import { useState } from "react";
import { Button } from "react-bootstrap";
import { FaFilter } from "react-icons/fa";
import { IoListSharp } from "react-icons/io5";
import { MdOutlineSwipe } from "react-icons/md";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { DotLoader } from "react-spinners";
import TinderCard from "react-tinder-card";
import CandidateJobBoardCard from "../../components/CandidateJobBoardCard";
import FiltersModal from "../../components/modals/FiltersModal.jsx";
import useJobs from "../../hooks/jobs/useJobs";
import useLikeJob from "../../hooks/jobs/useLikeJob";
import useToggleRejectJob from "../../hooks/jobs/useToggleRejectJob";

const SearchPage = () => {
  const userData = useSelector((state) => state.user.userData);
  const [filters, setFilters] = useState({});
  const [isShowModal, setIsShowModal] = useState(false);
  const [isListJobView, setIsListJobView] = useState(true);

  const queryObject = { ...filters, userId: userData.uid };

  const { jobs, isDataLoading, isFetching, refetch } = useJobs(queryObject);

  const { likeJob } = useLikeJob();
  const { toggleRejectJob } = useToggleRejectJob();

  const isFiltered = Object.values(filters).some((filter) => {
    if (Array.isArray(filter)) {
      return filter.length !== 0;
    } else if (typeof filter == "boolean") {
      return filter;
    }

    return false;
  });

  const onSwipe = (direction, job) => {
    switch (direction) {
      case "right":
        likeJob({
          userId: userData.uid,
          jobId: job.uid,
          jobUserId: job.userid,
          queryObject: queryObject,
        });
        return;
      case "left":
        toggleRejectJob({
          userId: userData.uid,
          jobId: job.uid,
          queryObject: queryObject,
        });
        return;
      default:
        return;
    }
  };

  return (
    <div className="flex justify-center text-white overflow-hidden">
      <div className="flex flex-column items-center my-1 md:my-10 w-screen">
        <div className="flex flex-column  mb-2 mt-8 md:mt-8 w-[90%] lg:w-[80%]">
          <div className="flex flex-col md:flex-row md:items-center space-between">
            <div className="flex flex-col mr-8 pb-4 md:pb-0 gap-3 flex-1">
              <p className="font-bold text-lg">
                Welcome to your personalised Job Board!
              </p>
              <p className="text-sm">
                Each job's score (%) reflects how well it matches your profile.
                Don't worry, no actions are permanent - except an application.
                All jobs remain accessible under{" "}
                <Link to="/my-matches" className="underline">
                  My Overview
                </Link>
                .
              </p>
            </div>
            <div className="flex gap-2">
              <Button
                className="w-1/2 p-0 flex sm:hidden" // Change to flex-1 for equal width
                onClick={() => setIsListJobView(!isListJobView)}
              >
                {isListJobView ? (
                  <>
                    Swipe view <MdOutlineSwipe />
                  </>
                ) : (
                  <>
                    List view <IoListSharp />
                  </>
                )}
              </Button>

              <div className="flex flex-col sm:flex-row w-1/2 sm:w-full items-center gap-2">
                <Button
                  className="flex-1 w-full p-[2px] sm:p-[20px]"
                  style={{
                    backgroundColor: userData.isEmployer
                      ? "#FFC83A"
                      : undefined,
                  }}
                  onClick={() => setIsShowModal(true)}
                >
                  Expand search
                  {isFiltered && <FaFilter />}
                </Button>
                <div className="">
                  {jobs?.length} {isFiltered && <>filtered </>} results
                </div>
              </div>
            </div>
          </div>
          <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-5 mt-1 md:mt-3 auto-rows-max grid-auto-column">
            {isDataLoading || isFetching ? (
              <DotLoader color="#868bff" />
            ) : jobs.length === 0 ? (
              isFiltered ? (
                <>No results for these filters</>
              ) : (
                <>
                  No ideal results found. Consider expanding your search beyond
                  your current preferences.
                </>
              )
            ) : (
              jobs.map((job) => (
                <div key={job.uid} className="flex flex-col h-full">
                  <div
                    className={`${isListJobView ? "hidden" : "block"} sm:hidden h-full`}
                  >
                    <TinderCard
                      className="pressable absolute left-0 w-full h-full max-h-[620px]"
                      onSwipe={(direction) => onSwipe(direction, job)}
                      preventSwipe={["up", "down"]}
                      preventMove={["yAxis"]}
                      swipeThreshold={100}
                      swipeRequirementType="position"
                    >
                      <div className="p-[20px] h-full">
                        <CandidateJobBoardCard
                          job={job}
                          queryObject={queryObject}
                          className="h-full"
                          refetchJobs={refetch}
                        />
                      </div>
                    </TinderCard>
                  </div>
                  <div
                    className={`${!isListJobView ? "hidden" : "block"}  sm:block h-full mt-4`}
                  >
                    <CandidateJobBoardCard
                      job={job}
                      queryObject={queryObject}
                      className="h-full"
                      refetchJobs={refetch}
                    />
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
      <FiltersModal
        show={isShowModal}
        onSearch={setFilters}
        onClear={setFilters}
        onHide={setIsShowModal}
        userData={userData}
      />
    </div>
  );
};

export default SearchPage;
