import { useState } from "react";
import { Modal, Button, Alert } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import useUpdatePassword from "../../hooks/useUpdatePassword";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { DotLoader } from "react-spinners";

const validationSchema = yup
  .object()
  .shape({
    password: yup
      .string()
      .required("Password is required")
      .min(6, "Your password is too short."),
    passwordConfirmation: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  })
  .required();

const ChangePasswordModal = ({ show, onHide }) => {
  const { updatePassword, isLoading, error } = useUpdatePassword();
  const [isUpdated, setIsUpdated] = useState(false);
  const methods = useForm({
    defaultValues: {
      password: "",
      passwordConfirmation: "",
    },
    resolver: yupResolver(validationSchema),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  const onSubmit = async (data) => {
    updatePassword(data.password, {
      onSuccess: () => {
        setIsUpdated(true);
      },
    });
  };

  const handleOnClose = () => {
    setIsUpdated(false);
    onHide();
    reset();
  };

  return (
    <Modal show={show} onHide={handleOnClose} centered>
      <FormProvider {...methods}>
        <form style={{ width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title className="text-black">Change Password</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {isUpdated ? (
              <Alert key={"success"} variant={"success"}>
                Password is updated successfully!
              </Alert>
            ) : (
              <div className="flex flex-col w-full">
                <Form.Group className="flex flex-column w-100">
                  <Form.Control
                    type={"password"}
                    placeholder="Password"
                    className="mb-2"
                    {...register("password")}
                  />
                  {errors.password && (
                    <p className="text-red-600">{errors.password.message}</p>
                  )}
                  <Form.Control
                    type={"password"}
                    placeholder="Confirm new password"
                    className="mb-2"
                    {...register("passwordConfirmation")}
                  />
                  {errors.passwordConfirmation && (
                    <p className="text-red-600">
                      {errors.passwordConfirmation.message}
                    </p>
                  )}
                </Form.Group>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              disabled={isLoading || isUpdated}
              type="submit"
              className="bg-[#46d5e9] disabled:bg-[#46d5e9] disabled:border-[#46d5e9] hover:bg-[#46d5e9] hover:border-[#46d5e9] border-[#46d5e9] text-[#18293d]"
            >
              <p className="flex gap-2">
                Change Password
                {isLoading && <DotLoader color="#FFF" size={"18px"} />}
              </p>
            </Button>
          </Modal.Footer>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default ChangePasswordModal;
