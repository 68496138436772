import { getAuth, signOut, signInWithCustomToken } from "firebase/auth";
import firebase from "firebase/compat/app";
import { arrayUnion, getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import "firebase/compat/firestore";

const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
};
// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);

const firestore = getFirestore(app, import.meta.env.VITE_FIREBASE_DB);
const storage = getStorage(app);
const auth = getAuth(app);

if (import.meta.env.VITE_NODE_ENV === "production") {
  auth.tenantId = import.meta.env.VITE_FIREBASE_TENANT_ID;
}

const db = getFirestore(app, import.meta.env.VITE_FIREBASE_DB);

export {
  arrayUnion,
  auth,
  db,
  firestore,
  storage,
  signOut,
  signInWithCustomToken,
};
