import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { Button } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";

import useMyJob from "../../hooks/jobs/useMyJob";
import Content1 from "./Content1.jsx";
import Content2 from "./Content2.jsx";

import "react-quill/dist/quill.snow.css";

const EditJobModal = ({ job, showModal, handleClose }) => {
  const { updateMyJob } = useMyJob(job.uid);
  const [page, setPage] = useState("Page1");

  const validationSchema = yup
    .object()
    .shape({
      jobTitle: yup.string().required("Please enter a job title"),
      jobCities: yup
        .array()
        .of(yup.string())
        .min(1, "Please enter your job required minimum 1 city")
        .required(),
      jobLanguages: yup
        .array()
        .of(yup.string())
        .min(1, "Please enter your job required minimum 1 language")
        .required(),
      jobExpertise: yup
        .array()
        .of(yup.string())
        .min(1, "Please enter your job required minimum 1 expert")
        .required(),
      isStudent: yup.boolean(),
      jobExperience: yup
        .object()
        .shape({
          min: yup.number().required(),
          max: yup.number().required(),
        })
        .required("Please enter a job budget range"),
      jobBudget: yup
        .object()
        .shape({
          min: yup.number().required(),
          max: yup.number().required(),
        })
        .required("Please enter a job budget range"),
      jobDescription: yup.string().required("Please enter a job description"),
    })
    .required();

  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      jobTitle: job.jobTitle,
      jobCities: job.jobCities,
      jobLanguages: job.jobLanguages,
      jobExpertise: job.jobExpertise,
      jobSkills: job.jobSkills,
      isStudent: job.isStudent,
      jobExperience: { min: job.jobExperienceMin, max: job.jobExperienceMax },
      jobBudget: { min: job.minJobBudget, max: job.maxJobBudget },
      jobDescription: job.jobDescription,
      jobStatus: job.jobStatus,
    },
    resolver: yupResolver(validationSchema),
  });

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit: handleFormSubmit,

    register,
    watch,
  } = methods;

  const handleSubmit = handleFormSubmit(async (formData) => {
    const jobData = {
      ...job,
      jobTitle: formData.jobTitle,
      jobCities: formData.jobCities,
      jobLanguages: formData.jobLanguages,
      jobExpertise: formData.jobExpertise,
      jobSkills: formData.jobSkills,
      jobDescription: formData.jobDescription,
      isStudent: formData.isStudent,
      jobExperienceMin: formData.jobExperience.min,
      jobExperienceMax: formData.jobExperience.max,
      minJobBudget: formData.jobBudget.min,
      maxJobBudget: formData.jobBudget.max,
      jobStatus: job.jobStatus,
    };

    updateMyJob(
      { uid: jobData.uid, job: jobData },
      { onSuccess: () => handleClose() }
    );
  });

  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header className="w-full">
        <Row className="w-full">
          <Row>
            {page === "Page1" && <Modal.Title>Job info</Modal.Title>}
            {page === "Page2" && <Modal.Title>Job Details</Modal.Title>}
          </Row>
          <Row className="flex items-center justify-between">
            <Col className="flex justify-around mt-2">
              <Button
                className="text-xs"
                variant={page === "Page1" ? "primary" : "secondary"}
                onClick={() => setPage("Page1")}
              >
                <p className="font-bold text-black">Job info</p>
              </Button>
              <Button
                className="text-xs"
                variant={page === "Page2" ? "primary" : "secondary"}
                onClick={() => setPage("Page2")}
              >
                <p className="font-bold text-black">Job Details</p>
              </Button>
            </Col>
          </Row>
        </Row>
      </Modal.Header>
      <Modal.Body>
        <FormProvider {...methods}>
          {page === "Page1" && (
            <Content1
              control={control}
              errors={errors}
              register={register}
              watch={watch}
            />
          )}
          {page === "Page2" && (
            <Content2 control={control} errors={errors} register={register} />
          )}
        </FormProvider>
      </Modal.Body>
      <Modal.Footer>
        <Row className="flex items-center justify-between w-full">
          <Col className="flex justify-end">
            <Button
              variant="primary"
              onClick={handleSubmit}
              disabled={isSubmitting}
            >
              <p className="font-bold text-blue-500">Save</p>
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

export default EditJobModal;
