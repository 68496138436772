import { useMutation, useQueryClient } from "@tanstack/react-query";
import app from "../../config/axiosConfig";

const _likeCandidate = async ({
  jobId,
  candidateId,
}: {
  jobId: string;
  candidateId: string;
}) => {
  const response = await app.put(`api/like-candidate`, {
    jobId: jobId,
    candidateId: candidateId,
  });
  if (response.status < 200 || response.status > 299) {
    throw new Error("Error liking candidate: " + response.statusText);
  }
  return response.data;
};

const useLikeCandidate = () => {
  const queryClient = useQueryClient();

  const { isPending: isUpdating, mutate: likeCandidate } = useMutation({
    mutationFn: _likeCandidate,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["candidates"],
      });
    },
  });

  return {
    isLoading: isUpdating,
    likeCandidate,
  };
};

export default useLikeCandidate;
