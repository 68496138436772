import { useRef, useEffect } from "react";
import { isToday, isYesterday } from "date-fns";
import { useNavigate } from "react-router-dom";
import useStatisticsPerJob from "../hooks/jobs/useStatisticsPerJob";
import useFetchConversationsForJob from "../hooks/useFetchConversationsForJob";

export const getJobStatusIndicatorColor = (status) => {
  switch (status) {
    case "draft":
      return "bg-[#ffc83a]";
    case "active":
      return "bg-[#4ac27a]";
    case "archive":
      return "bg-[#ef3b3b]";
    case "filled":
      return "bg-[#797de5]";
    default:
      return "bg-[#ffc83a]";
  }
};

const RecruiterHomeJobCard = ({
  jobData,
  isLast,
  setParentTotalUnreadCount,
}) => {
  const navigate = useNavigate();

  const { statisticsPerJob } = useStatisticsPerJob(jobData.uid);

  const { totalReceivedCounts } = useFetchConversationsForJob(
    jobData.uid,
    jobData.userid
  );

  const totalReceivedCount = Object.values(totalReceivedCounts).reduce(
    (acc, count) => acc + count,
    0
  );

  const prevTotalReceivedCountRef = useRef(0);

  useEffect(() => {
    const prevTotalReceivedCount = prevTotalReceivedCountRef.current;

    if (prevTotalReceivedCount !== totalReceivedCount) {
      const difference = totalReceivedCount - prevTotalReceivedCount;

      setParentTotalUnreadCount((prev) => prev + difference);

      prevTotalReceivedCountRef.current = totalReceivedCount;
    }
  }, [totalReceivedCount, setParentTotalUnreadCount]);

  const formatDate = (dateString) => {
    const formattedDate = new Date(dateString);

    // Check if the date is today or yesterday
    if (isToday(formattedDate)) {
      return "Today";
    } else if (isYesterday(formattedDate)) {
      return "Yesterday";
    } else {
      // If not today or yesterday, display the actual date
      const options = { year: "numeric", month: "long", day: "numeric" };
      return formattedDate.toLocaleDateString(undefined, options);
    }
  };

  return (
    <div
      className={`flex gap-2 items-center pb-3 cursor-pointer ${
        isLast ? "" : "border-b-2 border-[#18273A]"
      }`}
      onClick={() => navigate(`/my-jobs`)}
    >
      <p className="flex-1">{jobData.jobTitle}</p>
      <p className="flex-1">{formatDate(jobData?.createdAt)}</p>
      <p className="flex-1">{statisticsPerJob?.amountMatchedCandidates ?? 0}</p>
      <p className="flex-1">{statisticsPerJob?.amountApplicants ?? 0}</p>
      <p className="flex-1">{totalReceivedCount}</p>
      <p
        className={`flex-2 ${getJobStatusIndicatorColor(jobData.jobStatus)} w-10 h-2 rounded-md`}
      ></p>
    </div>
  );
};

export default RecruiterHomeJobCard;
