import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import app from "../../config/axiosConfig";

const _getMyJob = async (jobId: string) => {
  const response = await app.get(`api/my-jobs/${jobId}`);
  if (response.status < 200 || response.status > 299) {
    throw new Error("Error fetching my job: " + response.statusText);
  }
  return response.data;
};

const _updateMyJob = async (job: { uid: string }) => {
  const response = await app.put(`api/my-jobs/${job.uid}`, job);
  if (response.status < 200 || response.status > 299) {
    throw new Error("Error updating my job: " + response.statusText);
  }
  return response.data;
};

const _deleteMyJob = async (jobId: string) => {
  const response = await app.delete(`api/my-jobs/${jobId}`);
  if (response.status < 200 || response.status > 299) {
    throw new Error("Error deleting my job: " + response.statusText);
  }
  return response.data;
};

const useMyJob = (jobId: string, enabled = true) => {
  const queryClient = useQueryClient();
  const { data, error, isLoading } = useQuery({
    queryKey: ["my-jobs", jobId],
    queryFn: () => _getMyJob(jobId),
    placeholderData: (prevData) => prevData,
    enabled: enabled,
  });

  const { isPending: isUpdating, mutate: updateMyJob } = useMutation({
    mutationFn: _updateMyJob,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["my-jobs"],
      });
      queryClient.invalidateQueries({
        queryKey: ["candidates"],
      });
    },
  });

  const { isPending: isDeleting, mutate: deleteMyJob } = useMutation({
    mutationFn: _deleteMyJob,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["my-jobs"],
      });
    },
  });

  return {
    myJob: data,
    error,
    isMyJobLoading: isLoading || isDeleting || isUpdating,
    deleteMyJob,
    updateMyJob,
  };
};

export default useMyJob;
