import { useState } from "react";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal"; // import { Modal, Button } from "react-bootstrap"
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { saveData } from "../../app/dataSlice.js";
import { editUser } from "../../app/functions.js";
import { updateInitialData } from "../../app/initialDataSlice.js";
import { IMAGES } from "../../constants/images.jsx";
import {
  checkFileSize,
  copyFile,
  deleteFile,
  ENUM_FILE_TYPE,
  uploadFile,
} from "../../utils/crudFiles.ts";
import ApplicationPreview from "./ApplicationPreview.jsx";

export const PAGES = {
  APPLY: 0,
  PREVIEW: 1,
  SUCCESS: 2,
};

const ModalCandidateJobApply = ({
  showModal,
  handleClose,
  setApplied,
  job_id,
  refetchJobs,
}) => {
  const dispatch = useDispatch();
  const initialData = useSelector((state) => state.initialData);
  const [page, setPage] = useState(PAGES.APPLY);

  const handleEditUser = async (userData) => {
    await editUser(userData, dispatch, saveData);
  };

  const handleUploadCV = async (event) => {
    const file = event.target.files[0];

    if (!file || !checkFileSize(file)) {
      return;
    }
    const filePath = `cvs/${file.size}${file.name}/${uuidv4()}`;
    await uploadFile(
      initialData.uid,
      initialData,
      file,
      filePath,
      dispatch,
      updateInitialData,
      `${ENUM_FILE_TYPE.APPLICATION}Cv`,
      handleEditUser
    );
  };

  const handleUploadCoverLetter = async (event) => {
    const file = event.target.files[0];

    if (!file || !checkFileSize(file)) {
      return;
    }

    const filePath = `coverLetter/${file.size}${file.name}/${uuidv4()}`;
    await uploadFile(
      initialData.uid,
      initialData,
      file,
      filePath,
      dispatch,
      updateInitialData,
      `${ENUM_FILE_TYPE.APPLICATION}CoverLetter`,
      handleEditUser
    );
  };

  const handleCopyCV = async (event) => {
    await copyFile(
      initialData,
      initialData.cvPath,
      `${ENUM_FILE_TYPE.APPLICATION}/${initialData.cvPath}/${uuidv4()}`,
      dispatch,
      updateInitialData,
      handleEditUser
    );
  };

  const handleCloseModal = async () => {
    try {
      if (initialData.applicationCoverLetterPath != null) {
        await deleteFile(
          initialData.uid,
          initialData,
          initialData.applicationCoverLetterPath,
          dispatch,
          updateInitialData,
          `${ENUM_FILE_TYPE.APPLICATION}CoverLetter`,
          0,
          handleEditUser
        );
      }

      if (initialData.applicationCvPath != null) {
        await deleteFile(
          initialData.uid,
          {
            ...initialData,
            applicationCoverLetterPath: null,
            applicationCoverLetter: null,
          },
          initialData.applicationCvPath,
          dispatch,
          updateInitialData,
          `${ENUM_FILE_TYPE.APPLICATION}Cv`,
          0,
          handleEditUser
        );
      }

      if (page === PAGES.SUCCESS) {
        setApplied(true);
        refetchJobs();
      }

      handleClose();
    } catch (error) {
      console.error("Error while deleting files or closing the modal", error);
    }
  };

  return (
    <>
      <Modal
        show={showModal}
        onHide={async () => await handleCloseModal()}
        className="flex justify-center items-center text-black"
        centered
      >
        <Modal.Body className="flex justify-center items-center">
          <Row className="flex flex-column justify-center bg-white rounded-md p-3">
            {page === PAGES.PREVIEW || page === PAGES.SUCCESS ? (
              <>
                <ApplicationPreview
                  page={page}
                  setPage={setPage}
                  handleClose={handleClose}
                  setApplied={(e) => setApplied(e)}
                  refetchJobs={refetchJobs}
                />
              </>
            ) : (
              <>
                <Row className="text-black text-center mt-3">
                  <p className="font-bold text-2xl mb-1">Ready To Apply?</p>
                  <p>Pick how you want to apply below</p>
                </Row>
                {/* attachments options */}
                <Row className="flex flex-column items-center my-5 ">
                  {/* upper */}
                  <Row className="flex justify-around ">
                    <Row className="flex justify-center items-center border-b-2 border-gray-300 p-1  w-[133px] ">
                      <Row
                        className={`flex flex-column items-center text-center p-1 cursor-pointer rounded-md w-[133px] hover:scale-105 flex-1 ${
                          initialData.applicationCvPath != null
                            ? "border-2 bg-[#F1F1F5]"
                            : ""
                        }`}
                      >
                        <label
                          className="flex flex-column items-center gap-y-2 cursor-pointer"
                          htmlFor="newCV"
                        >
                          <img className="" src={IMAGES.BOOK} />
                          {initialData.applicationCvPath == null ? (
                            <>Upload CV</>
                          ) : (
                            <>CV is uploaded </>
                          )}
                        </label>
                        <Form.Control
                          type="file"
                          id="newCV"
                          className="invisible"
                          onChange={async (event) =>
                            await handleUploadCV(event)
                          }
                          style={{ display: "none" }} // Alternatively, you can use inline style
                        />
                      </Row>
                    </Row>
                    <Row className="flex justify-center items-center border-b-2 border-gray-300 p-1  w-[133px] ">
                      <Row
                        className={`flex flex-column items-center text-center p-1 cursor-pointer rounded-md w-[133px] hover:scale-105 flex-1 ${
                          initialData.applicationCoverLetterPath != null
                            ? "border-2 bg-[#F1F1F5]"
                            : ""
                        }`}
                      >
                        <Form.Control
                          type="file"
                          id="newCL"
                          className="invisible"
                          onChange={async (event) =>
                            await handleUploadCoverLetter(event)
                          }
                          style={{ display: "none" }} // Alternatively, you can use inline style
                        />
                        <label
                          className="flex flex-column items-center gap-y-2 cursor-pointer"
                          htmlFor="newCL"
                        >
                          <img className="" src={IMAGES.BOOK} />
                          {initialData.applicationCoverLetterPath == null ? (
                            <> Upload Cover Letter</>
                          ) : (
                            <>Cover Letter is uploaded </>
                          )}
                        </label>
                      </Row>
                    </Row>
                  </Row>
                  {/* lower */}
                  <Row className="flex justify-around mt-4">
                    {initialData.cv != null && initialData.cv !== "" && (
                      <Row
                        className={`flex flex-column items-center justify-center text-center p-1 gap-y-2 cursor-pointer rounded-md w-[32%] hover:scale-105
                      ${initialData.applicationCv != null ? "bg-[#F1F1F5]" : ""}`}
                      >
                        <label
                          className="flex flex-column items-center gap-y-2 cursor-pointer"
                          htmlFor="copyCV"
                        >
                          <img src={IMAGES.CONTAINER} />
                          {initialData.applicationCv == null ? (
                            <>Use the CV saved in your profile</>
                          ) : (
                            <>Used the CV saved in your profile</>
                          )}
                          <Button
                            id="copyCV"
                            className="invisible"
                            onClick={async (event) => await handleCopyCV(event)}
                            style={{ display: "none" }}
                          />
                        </label>
                      </Row>
                    )}
                    <Row
                      className={`flex flex-column items-center justify-center text-center p-1 cursor-pointer rounded-md w-[32%] hover:scale-105
                      `}
                      onClick={() => {
                        setPage(PAGES.PREVIEW);
                      }}
                    >
                      <img className="h-6 w-16" src={IMAGES.R_ARROW} />
                      <p className="mt-2">Skip this step</p>
                    </Row>
                  </Row>
                </Row>
                {/* buttons */}
                <Row className="flex flex-column items-center gap-y-3 mb-3">
                  <button
                    className="bg-[#46D6EA] w-75 py-2 rounded-md font-bold  border-1 border-[#46D6EA] cursor-pointer hover:bg-transparent"
                    onClick={() => setPage(PAGES.PREVIEW)}
                  >
                    Click to continue
                  </button>
                  <button
                    className="  py-2 rounded-md w-75 font-bold  border-1 border-black cursor-pointer hover:bg-transparent"
                    onClick={async () => await handleCloseModal()}
                  >
                    Cancel
                  </button>
                </Row>
              </>
            )}
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalCandidateJobApply;
