const NOTIFICATION_TYPES = {
  APPLY_JOB: "APPLY_JOB",
  MATCH_JOB: "MATCH_JOB",
  LIKE_CANDIDATE: "LIKE_CANDIDATE",
  LIKE_JOB: "LIKE_JOB",
  REJECT_CANDIDATE: "REJECT_CANDIDATE",
  ACCEPT_CANDIDATE: "ACCEPT_CANDIDATE",
  MATCH_CANDIDATE: "MATCH_CANDIDATE",
};

const CANDIDATE_TYPES = [
  NOTIFICATION_TYPES.LIKE_CANDIDATE,
  NOTIFICATION_TYPES.MATCH_CANDIDATE,
  NOTIFICATION_TYPES.REJECT_CANDIDATE,
  NOTIFICATION_TYPES.ACCEPT_CANDIDATE,
  NOTIFICATION_TYPES.APPLY_JOB,
  NOTIFICATION_TYPES.MATCH_JOB,
  NOTIFICATION_TYPES.LIKE_JOB,
];

const JOB_TYPES = [NOTIFICATION_TYPES.MATCH_JOB, NOTIFICATION_TYPES.LIKE_JOB];

export { default } from "./NotificationDropdown.jsx";
export { CANDIDATE_TYPES, JOB_TYPES, NOTIFICATION_TYPES };
