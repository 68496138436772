import { memo, useEffect } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import CreatableSelect from "react-select/creatable";
import {
  belgiumCitiesOptions,
  expertiseOptions,
  jobExperience,
  languagesOptions,
} from "../../constants/Data";
import { IMAGES } from "../../constants/images";
import useMyJobs from "../../hooks/jobs/useMyJobs";

const customStyles = {
  control: (provided) => ({
    ...provided,
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "black", // Change text color here
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "grey", // Change placeholder color here
  }),
};

const customComponents = {
  IndicatorSeparator: () => null, // Remove IndicatorSeparator component
};

const FiltersModal = memo(({ show, onHide, onClear, onSearch, userData }) => {
  const { myJobs } = useMyJobs(userData.isEmployer);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
    register,
    watch,
    reset,
  } = useForm({
    defaultValues: {
      jobId: JSON.parse(localStorage.getItem("jobId")) || [],
      jobCities: JSON.parse(localStorage.getItem("jobCities")) || [],
      jobExpertise: JSON.parse(localStorage.getItem("jobExpertise")) || [],
      jobExperience: JSON.parse(localStorage.getItem("jobExperience")) || [],
      jobLanguages: JSON.parse(localStorage.getItem("jobLanguages")) || [],
      isStudent: JSON.parse(localStorage.getItem("isStudent")) || false,
    },
  });

  const [isStudent] = watch(["isStudent"]);

  const handleSearch = handleSubmit(async (formData) => {
    // Save form values to localStorage
    localStorage.setItem("jobId", JSON.stringify(formData.jobId));
    localStorage.setItem("jobCities", JSON.stringify(formData.jobCities));
    localStorage.setItem("jobExpertise", JSON.stringify(formData.jobExpertise));
    localStorage.setItem(
      "jobExperience",
      JSON.stringify(formData?.jobExperience ?? [])
    );
    localStorage.setItem("jobLanguages", JSON.stringify(formData.jobLanguages));
    localStorage.setItem("isStudent", JSON.stringify(formData.isStudent));

    await onSearch(formData);
    onHide();
  });

  const handleClearSearch = handleSubmit(async (formData) => {
    reset({
      jobId: [],
      jobCities: [],
      jobExpertise: [],
      jobExperience: [],
      jobLanguages: [],
      isStudent: false,
    });

    onClear();

    localStorage.setItem("jobId", JSON.stringify([]));
    localStorage.setItem("jobCities", JSON.stringify([]));
    localStorage.setItem("jobExpertise", JSON.stringify([]));
    localStorage.setItem("jobExperience", JSON.stringify([]));
    localStorage.setItem("jobLanguages", JSON.stringify([]));
    localStorage.setItem("isStudent", JSON.stringify(false));

    await onSearch({});
  });

  useEffect(() => {
    const savedJobId = JSON.parse(localStorage.getItem("jobId")) || [];
    const savedJobCities = JSON.parse(localStorage.getItem("jobCities")) || [];
    const savedJobExpertise =
      JSON.parse(localStorage.getItem("jobExpertise")) || [];
    const savedJobExperience =
      JSON.parse(localStorage.getItem("jobExperience")) || [];
    const savedJobLanguages =
      JSON.parse(localStorage.getItem("jobLanguages")) || [];

    const savedStudents =
      JSON.parse(localStorage.getItem("isStudent")) || false;

    setValue("jobId", savedJobId);
    setValue("jobCities", savedJobCities);
    setValue("jobExpertise", savedJobExpertise);
    setValue("jobExperience", savedJobExperience);
    setValue("jobLanguages", savedJobLanguages);
    setValue("isStudent", savedStudents);

    const filtersPredefined = {
      jobId: savedJobId,
      jobCities: savedJobCities,
      jobExpertise: savedJobExpertise,
      jobExperience: savedJobExpertise,
      jobLanguages: savedJobLanguages,
      isStudent: savedStudents,
    };

    onSearch(filtersPredefined);
  }, [setValue, myJobs, onSearch]);

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title className="text-black">Search filters</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="flex flex-col gap-4 items-start w-100">
          {myJobs != null && myJobs.length > 0 && (
            <Controller
              className="w-full border-r-2 border-gray-400 pr-3"
              name="jobId"
              control={control}
              render={({ field }) => {
                const selectedOption =
                  myJobs?.find((job) => job.uid === field.value) ?? null;

                return (
                  <CreatableSelect
                    {...field}
                    isClearable
                    placeholder="Select job"
                    components={customComponents}
                    className="basic-multi-select text-black w-100"
                    name="jobs"
                    options={myJobs?.map((job) => ({
                      value: job.uid,
                      label: job.jobTitle,
                    }))}
                    classNamePrefix="select"
                    styles={customStyles}
                    onChange={(selectedOption) => {
                      field.onChange(
                        selectedOption ? selectedOption.value : []
                      );
                    }}
                    value={
                      selectedOption != null
                        ? {
                            value: selectedOption?.uid,
                            label: selectedOption?.jobTitle,
                          }
                        : []
                    }
                  />
                );
              }}
            />
          )}
          <Controller
            className="w-full border-r-2 border-gray-400 pr-3 "
            name="jobCities"
            control={control}
            render={({ field }) => (
              <CreatableSelect
                {...field}
                isClearable
                placeholder="Location"
                isMulti
                components={customComponents}
                className="basic-multi-select text-black w-100"
                name="cities"
                options={belgiumCitiesOptions}
                classNamePrefix="select"
                styles={customStyles}
                onChange={(selectedOptions) => {
                  const formattedValue = selectedOptions.map(
                    (option) => option.label
                  );
                  field.onChange(formattedValue);
                }}
                value={
                  field.value
                    ? field.value.map((label) => ({ label, value: label }))
                    : []
                }
              />
            )}
          />
          <Controller
            className="w-full border-r-2 border-gray-400 pr-3"
            name="jobExpertise"
            control={control}
            render={({ field }) => (
              <CreatableSelect
                {...field}
                isClearable
                placeholder="Expertise"
                isMulti
                className="basic-multi-select text-black w-100"
                styles={customStyles}
                components={customComponents}
                classNamePrefix="select"
                options={expertiseOptions}
                onChange={(selectedOptions) => {
                  const formattedValue = selectedOptions.map(
                    (option) => option.label
                  );
                  field.onChange(formattedValue);
                }}
                value={
                  field.value
                    ? field.value.map((label) => ({ label, value: label }))
                    : []
                }
              />
            )}
          />
          <Form.Check
            {...register("isStudent")}
            type={"checkbox"}
            id={`isStudent`}
            label={
              userData.isEmployer
                ? "Looking for students"
                : "looking for a summer internship or bar traineeship"
            }
          />
          <Controller
            disabled={isStudent}
            className="w-full border-r-2 border-gray-400 pr-3"
            name="jobExperience"
            control={control}
            render={({ field }) => {
              const selectedOption = jobExperience.find(
                (positionOption) => field?.value === positionOption
              );
              return (
                <CreatableSelect
                  isDisabled={isStudent}
                  {...field}
                  placeholder="Experience"
                  isClearable
                  components={customComponents}
                  className="basic-multi-select text-black w-100"
                  name="jobExperience"
                  options={jobExperience.map((experience) => ({
                    label: experience,
                    value: experience,
                  }))}
                  classNamePrefix="select"
                  styles={customStyles}
                  onChange={(selectedOption) => {
                    field.onChange(selectedOption ? selectedOption.value : []);
                  }}
                  value={
                    selectedOption != null
                      ? { label: selectedOption, value: selectedOption }
                      : []
                  }
                />
              );
            }}
          />
          <Controller
            className="w-full border-r-2 border-gray-400 pr-3"
            name="jobLanguages"
            control={control}
            render={({ field }) => (
              <CreatableSelect
                {...field}
                isClearable
                placeholder="Languages"
                isMulti
                className="basic-multi-select text-black w-100"
                styles={customStyles}
                components={customComponents}
                classNamePrefix="select"
                defaultValue={""}
                name="Languages"
                options={languagesOptions}
                onChange={(selectedOptions) => {
                  const formattedValue = selectedOptions.map(
                    (option) => option.label
                  );
                  field.onChange(formattedValue);
                }}
                value={
                  field.value
                    ? field.value.map((label) => ({ label, value: label }))
                    : []
                }
              />
            )}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          type="submit"
          onClick={handleClearSearch}
          disabled={isSubmitting}
        >
          Clear All
          <img src={IMAGES.TRASH_CAN} alt="filter" className="h-4 ml-2" />
        </Button>

        <Button type="submit" onClick={handleSearch} disabled={isSubmitting}>
          Search
        </Button>
      </Modal.Footer>
    </Modal>
  );
});

export default FiltersModal;
