import { Button } from "react-bootstrap";
import { Controller } from "react-hook-form";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { E_JOB_STATUS } from "../../constants/enums";

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, false] }],
    ["bold", "italic", "underline", "strike"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image"],
    ["clean"],
  ],
};

const JobDescriptionStep = ({
  control,
  errors,
  onPrev,
  register,
  setValue,
}) => {
  const handlePrevStepClick = () => {
    onPrev();
  };

  return (
    <div className="w-100 h-full">
      <p className="font-bold">Job Description</p>
      {/* the rest of the form */}
      <div className="w-100">
        {/* Description */}
        <div className="flex flex-column w-100">
          <label className="my-2">
            Please provide a job description. The more information included, the
            better candidates can assess whether the position is appealing to
            them.
          </label>
          <Controller
            name="jobDescription"
            control={control}
            render={({ field }) => {
              return (
                <ReactQuill
                  {...field}
                  modules={modules}
                  placeholder="Write a description of the job which might help the candidates decide whether they are the right fit"
                />
              );
            }}
          />
          {errors.jobDescription && (
            <p className="text-red-400">{errors.jobDescription.message}</p>
          )}
        </div>
      </div>
      {/* button */}
      <div className="flex mt-4 justify-between my-3">
        <div className="flex flex-row justify-between w-50">
          <Button variant="secondary" onClick={handlePrevStepClick}>
            Back
          </Button>
          <p className=" text-center">2/2</p>
        </div>
        <div className="flex flex-column justify-between gap-2">
          <Button
            type="submit"
            {...register("jobStatus")}
            onClick={() => setValue("jobStatus", E_JOB_STATUS.active)}
          >
            Publish
          </Button>
          <Button
            type="submit"
            variant="outlined"
            {...register("jobStatus")}
            onClick={() => setValue("jobStatus", E_JOB_STATUS.draft)}
          >
            Save as draft
          </Button>
        </div>
      </div>
    </div>
  );
};

export default JobDescriptionStep;
