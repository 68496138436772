import { useCallback, useEffect, useState } from "react";
import { Button, Tab, Tabs } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { DotLoader } from "react-spinners";
import CandidateCard from "../../components/CandidateCard";
import RecruiterMatchModal from "../../components/RecruiterMatchModal.jsx";
import useCandidates from "../../hooks/candidates/useCandidates";
import useMyJob from "../../hooks/jobs/useMyJob";
import useStatisticsPerJob from "../../hooks/jobs/useStatisticsPerJob";
import NavbarRec from "../../utils/NavBarRec";

export const TABS_JOB_PAGE = {
  Potential: "potential",
  Applicants: "applicants",
  Liked: "saved",
  Matches: "matches",
  Likes: "likes",
};

const JobPage = () => {
  const { id } = useParams();
  const { state } = useLocation();
  const { defaultTab } = state;
  const [showMatchModal, setShowMatchModal] = useState(false);
  const [activeTab, setActiveTab] = useState(
    defaultTab ?? TABS_JOB_PAGE.Applicants
  );
  const { statisticsPerJob } = useStatisticsPerJob(id);
  const { myJob, isMyJobLoading } = useMyJob(id);

  const getQueryBasedOnTab = useCallback(
    (jobId, activeTab) => {
      switch (activeTab) {
        case TABS_JOB_PAGE.Potential:
          return { jobId, queryField: TABS_JOB_PAGE.Potential };
        case TABS_JOB_PAGE.Applicants:
          return { jobId, queryField: TABS_JOB_PAGE.Applicants };
        case TABS_JOB_PAGE.Liked:
          return { jobId, queryField: TABS_JOB_PAGE.Liked };
        case TABS_JOB_PAGE.Matches:
          return { jobId, queryField: TABS_JOB_PAGE.Matches };
        case TABS_JOB_PAGE.Likes:
          return { jobId, queryField: TABS_JOB_PAGE.Likes };
        default:
          return {};
      }
    },
    [activeTab, state]
  );

  const { candidates, isCandidatesLoading } = useCandidates(
    getQueryBasedOnTab(id, activeTab)
  );

  useEffect(() => {
    setActiveTab(defaultTab);
  }, [defaultTab, state]);

  const handleCloseMatchModal = () => {
    setShowMatchModal(false);
  };

  const tabClass = (tabKey) => {
    return tabKey === activeTab
      ? "text-[#46D6EA] border-b-[2px] border-[#46D6EA] pb-1 font-bold"
      : "text-white";
  };
  const handleTabSelect = (key) => {
    setActiveTab(key);
  };

  const navigate = useNavigate();

  const handelJobOverview = () => {
    navigate(`/job-overview/${myJob.uid}`);
  };

  const handleCandidateClick = (candidateId, activeTab) => () => {
    navigate(`/candidate-preview/${candidateId}/${myJob.uid}`, {
      state: {
        jobId: myJob.uid,
        jobTab: activeTab,
      },
    });
  };

  if (isMyJobLoading) {
    return;
  }

  return (
    <>
      <div
        className="flex flex-column items-center h-screen text-white relative z-0"
        style={{
          height: "calc(100vh - 62px)", // Adjust the height based on your navbar height
        }}
      >
        <div className="flex flex-column items-center justify-center w-[90%] lg:w-[80%]">
          <div className="flex flex-row space-between w-full mt-20">
            <div>
              <p className="font-bold text-lg">{myJob?.jobTitle}</p>
              <p className="text-sm text-white opacity-60">
                Posted {new Date(myJob.createdAt).toLocaleDateString()}
              </p>
            </div>
            <div>
              <Button
                variant="outlined"
                style={{
                  color: "#FFC83A",
                  borderColor: "#FFC83A",
                }}
                onClick={handelJobOverview}
              >
                Preview job post
              </Button>
            </div>
          </div>
          {/* search bar */}
          {isCandidatesLoading && <DotLoader color="#868bff" />}
          <div className="mt-4 absolute top-[280px]  md:top-[163px] w-[90%] lg:w-[80%] pb-14">
            <Tabs
              defaultActiveKey={defaultTab}
              id="noanim-tab-example"
              variant=""
              className="flex flex-col md:flex-row mb-3 border-none ml-[-14px]"
              onSelect={handleTabSelect}
            >
              <Tab
                eventKey={TABS_JOB_PAGE.Potential}
                active={TABS_JOB_PAGE.Potential === activeTab}
                title={
                  <p className="text-white">
                    <span
                      className={`pt-4 mr-3 ${tabClass(TABS_JOB_PAGE.Potential)}`}
                    >
                      Potential Candidates
                    </span>
                    ({statisticsPerJob?.amountPotentialCandidates ?? 0})
                  </p>
                }
              >
                {TABS_JOB_PAGE.Potential === activeTab && (
                  <div className="flex flex-column mt-2">
                    {/* candidates parts */}
                    <div className="mt-3">
                      {/* candidates cards container */}
                      <div className="grid grid-cols-1 lg:grid-cols-2 lg:grid-cols-3 gap-5 text-black relative auto-rows-max grid-auto-column gap-x-5">
                        {/* candidate card */}
                        {candidates?.map((candidate) => (
                          <CandidateCard
                            key={candidate.uid}
                            data={candidate}
                            job={myJob}
                            origin={"potential"}
                            onClick={handleCandidateClick(
                              candidate.uid,
                              TABS_JOB_PAGE.Potential
                            )}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </Tab>
              <Tab
                eventKey={TABS_JOB_PAGE.Liked}
                active={TABS_JOB_PAGE.Liked === activeTab}
                title={
                  <p className="text-white">
                    <span
                      className={`pt-4 mr-3 ${tabClass(TABS_JOB_PAGE.Liked)}`}
                    >
                      Liked Candidates
                    </span>{" "}
                    ({statisticsPerJob?.amountSavedCandidates ?? 0})
                  </p>
                }
              >
                {TABS_JOB_PAGE.Liked === activeTab && (
                  <div className="flex flex-column mt-2">
                    {/* candidates parts */}
                    <div className="mt-3">
                      {/* candidates cards container */}
                      <div className="grid grid-cols-1 lg:grid-cols-2 lg:grid-cols-3 gap-5 text-black relative auto-rows-max grid-auto-column gap-x-5">
                        {/* candidate card */}
                        {candidates?.map((candidate) => (
                          <CandidateCard
                            key={candidate.uid}
                            data={candidate}
                            job={myJob}
                            origin={"liked"}
                            onClick={handleCandidateClick(
                              candidate.uid,
                              TABS_JOB_PAGE.Liked
                            )}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </Tab>
              <Tab
                eventKey={TABS_JOB_PAGE.Likes}
                active={TABS_JOB_PAGE.Likes === activeTab}
                title={
                  <p className="text-white">
                    <span
                      className={`pt-4 mr-3 ${tabClass(TABS_JOB_PAGE.Likes)}`}
                    >
                      Likes job
                    </span>
                    ({statisticsPerJob?.amountCandidateLikes ?? 0})
                  </p>
                }
              >
                {TABS_JOB_PAGE.Likes === activeTab && (
                  <div className="flex flex-column mt-2 relative">
                    {/* candidates parts */}
                    <div className="mt-3">
                      {/* candidates cards container */}
                      <div className="grid grid-cols-1 lg:grid-cols-2 lg:grid-cols-3 gap-5 text-black relative auto-rows-max grid-auto-column gap-x-5">
                        {/* candidate card */}
                        {candidates?.map((candidate) => (
                          <CandidateCard
                            key={candidate.uid}
                            data={candidate}
                            job={myJob}
                            origin={"likes"}
                            onClick={handleCandidateClick(
                              candidate.uid,
                              TABS_JOB_PAGE.Likes
                            )}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </Tab>
              <Tab
                eventKey={TABS_JOB_PAGE.Matches}
                active={TABS_JOB_PAGE.Matches === activeTab}
                title={
                  <p className="text-white">
                    <span
                      className={`pt-4 mr-3 ${tabClass(TABS_JOB_PAGE.Matches)}`}
                    >
                      Matches
                    </span>
                    ({statisticsPerJob?.amountMatchedCandidates ?? 0})
                  </p>
                }
              >
                {TABS_JOB_PAGE.Matches === activeTab && (
                  <div className="flex flex-column mt-2 relative">
                    {/* candidates parts */}
                    <div className="mt-3">
                      {/* candidates cards container */}
                      <div className="grid grid-cols-1 lg:grid-cols-2 lg:grid-cols-3 gap-5 text-black relative auto-rows-max grid-auto-column gap-x-5">
                        {/* candidate card */}
                        {candidates?.map((candidate) => (
                          <CandidateCard
                            key={candidate.uid}
                            data={candidate}
                            job={myJob}
                            origin={"matches"}
                            onClick={handleCandidateClick(
                              candidate.uid,
                              TABS_JOB_PAGE.Matches
                            )}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </Tab>
              <Tab
                eventKey={TABS_JOB_PAGE.Applicants}
                active={TABS_JOB_PAGE.Applicants === activeTab}
                title={
                  <p className="text-white">
                    <span
                      className={`pt-4 mr-3 ${tabClass(TABS_JOB_PAGE.Applicants)}`}
                    >
                      Applicants
                    </span>
                    ({statisticsPerJob?.amountApplicants ?? 0})
                  </p>
                }
              >
                {TABS_JOB_PAGE.Applicants === activeTab && (
                  <div className="flex flex-column mt-2">
                    {/* candidates parts */}
                    <div className="mt-3">
                      {/* candidates cards container */}
                      <div className="grid grid-cols-1 lg:grid-cols-2 lg:grid-cols-3 gap-5 text-black relative auto-rows-max grid-auto-column gap-x-5">
                        {/* candidate card */}
                        {candidates?.map((candidate) => (
                          <CandidateCard
                            key={candidate.uid}
                            data={candidate}
                            job={myJob}
                            origin={"applicants"}
                            onClick={handleCandidateClick(
                              candidate.uid,
                              TABS_JOB_PAGE.Applicants
                            )}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
      <RecruiterMatchModal
        show={showMatchModal}
        handleClose={handleCloseMatchModal}
      />
    </>
  );
};

export default JobPage;
