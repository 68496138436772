import { Modal } from "react-bootstrap";
import Button, { buttonType } from "../Button";

const DeleteJobModal = ({ show, onHide, onDelete }) => {
  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title className="text-black">Confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="text-gray-700">
          Are you sure you would like to delete this job?
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button type={buttonType.LINK} onClick={onHide}>
          No
        </Button>
        <Button onClick={onDelete}>Yes</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteJobModal;
