// @ts-ignore
import { IMAGES } from "../../constants/images";

interface IApplicationStatusJobCardHeaderProps {
  applicationData: {
    accepted: boolean;
    rejected: boolean;
  };
}

const ApplicationStatusJobCardHeader = (
  props: IApplicationStatusJobCardHeaderProps
) => {
  const { applicationData } = props;

  let statusText = "Pending";
  let bgColor = "#D8E0EB";
  let bgColorChip = "#FFFFFF";
  let textColor = "#5B728E";
  let srcIcon = IMAGES.PENDING;

  if (applicationData.accepted) {
    statusText = "Accepted";
    bgColor = "#868BFF";
    bgColorChip = "#C5C7FF";
    textColor = "#323574";
    srcIcon = IMAGES.ACCEPTED;
  }

  if (applicationData.rejected) {
    statusText = "Rejected";
    bgColor = "#FF7878";
    bgColorChip = "#FFC4C4";
    textColor = "#6A1D1D";
    srcIcon = IMAGES.REJECTED;
  }

  return (
    <div
      className={`flex space-between rounded-t-md font-semibold py-4 px-6`}
      style={{
        backgroundColor: bgColor,
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: bgColorChip,
          color: textColor,
          borderRadius: "100px",
          boxShadow: "0px 1px 4px 0px #19293D57",
          gap: "8px",
          padding: "10px 33px",
        }}
      >
        <img src={srcIcon} width={15} height={14} />
        {statusText}
      </div>

      {statusText === "Accepted" && (
        <img src={IMAGES.CONFETTI} width={40} height={40} />
      )}
    </div>
  );
};

export default ApplicationStatusJobCardHeader;
