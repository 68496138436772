// Function to calculate the difference in years between two dates
export function calculateYearDifference(
  startDate: string | Date,
  endDate: string | Date
): number {
  const start = new Date(startDate);
  const end = new Date(endDate);
  let yearsDifference = end.getFullYear() - start.getFullYear();
  let monthsDifference = end.getMonth() - start.getMonth();
  let daysDifference = end.getDate() - start.getDate();

  if (monthsDifference < 0 || (monthsDifference === 0 && daysDifference < 0)) {
    yearsDifference--;
    monthsDifference += 12;
  }

  const totalYears =
    yearsDifference +
    (monthsDifference > 0
      ? Math.floor((monthsDifference / 12) * 100) / 100
      : 0);

  return totalYears;
}

// Function to calculate total years of experience
export function getTotalYearsOfExperience(
  employmentHistory: {
    employmentstart: string;
    employmentend: string;
    stillEmployed: boolean;
  }[]
): number {
  const currentDate = new Date();
  let totalYears = 0;

  for (const job of employmentHistory) {
    const startDate = job?.employmentstart
      ? new Date(job.employmentstart)
      : new Date();
    const endDate = job.stillEmployed
      ? currentDate
      : job?.employmentend
        ? new Date(job.employmentend)
        : new Date();

    totalYears += calculateYearDifference(startDate, endDate);
  }

  return Math.floor(totalYears);
}

export const messageDateDisplay = (date: string) => {
  const inputDate = new Date(date);
  const today = new Date();

  const isToday =
    inputDate.getDate() === today.getDate() &&
    inputDate.getMonth() === today.getMonth() &&
    inputDate.getFullYear() === today.getFullYear();

  if (isToday) {
    // Show time only
    return inputDate.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
  } else {
    const weekday = inputDate.toLocaleDateString("en-US", { weekday: "short" }); // e.g., "Fri"
    const month = inputDate.toLocaleDateString("en-US", { month: "short" }); // e.g., "Aug"
    const day = inputDate.toLocaleDateString("en-US", { day: "2-digit" }); // e.g., "02"
    const year = inputDate.getFullYear(); // e.g., "2024"

    const time = inputDate.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });

    return `${weekday} ${month} ${day} ${year} ${time}`;
  }
};
