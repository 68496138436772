import { useMutation } from "@tanstack/react-query";
// @ts-ignore
import app from "../config/axiosConfig";

const _resetPassword = async (email: string) => {
  try {
    const response = await app.post(`/api/reset-password`, { email });

    if (response.status < 200 || response.status > 299) {
      throw new Error(response.data.error);
    }

    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
};
const useResetPassword = () => {
  const {
    data,
    error,
    isPending: isLoading,
    mutate: resetPassword,
  } = useMutation({
    mutationFn: _resetPassword,
  });

  return { data, error, isLoading, resetPassword };
};

export default useResetPassword;
