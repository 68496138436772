import classNames from "classnames";
import styles from "./GradePercentages.module.css";

const grades = ["50-65%", "65-75%", "75-85%", "85-100%"];

const GradePercentages = ({ onSelect, selectedGrade }) => {
  return (
    <div className={styles.container}>
      {grades.map((grade) => (
        <button
          key={grade}
          className={classNames(styles.item, {
            [styles.isSelected]: selectedGrade === grade,
          })}
          onClick={() => onSelect(grade)}
          value={grade}
        >
          {grade}
        </button>
      ))}
    </div>
  );
};

export default GradePercentages;
