import { Modal } from "react-bootstrap";
import Button, { buttonType } from "./Button";

const IncognitoModal = ({
  isIncognito,
  isUpdating,
  onClose,
  onUpdateIncognitoStatus,
  show,
}) => {
  const handleSwitchToPublic = async () => {
    await onUpdateIncognitoStatus(false);

    onClose();
  };

  const handleSwitchToAnonymous = async () => {
    await onUpdateIncognitoStatus(true);

    onClose();
  };

  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Body className="border-t-8 border-[#868BFF] rounded-t-lg">
        <div className="flex flex-column items-center justify-center px-4">
          {isIncognito ? (
            <>
              <p className="font-bold text-2xl mb-3">Activate public mode</p>
              <p style={{ textAlign: "center" }}>
                You are about to activate public mode for this conversation. The
                law firm will be able to see your personal information.
              </p>
            </>
          ) : (
            <>
              <p className="font-bold text-2xl mb-3">Activate anonymous mode</p>
              <p style={{ textAlign: "center" }}>
                You are about to switch on anonymous mode for this conversation.
                The law firm won't be able to see your personal information.
              </p>
            </>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer className="flex items-center flex-column justify-center w-full">
        {isIncognito ? (
          <>
            <Button disabled={isUpdating} onClick={handleSwitchToPublic}>
              {isUpdating ? <>Switching...</> : <>Switch to public mode</>}
            </Button>
            <Button
              type={buttonType.LINK}
              disabled={isUpdating}
              onClick={onClose}
            >
              Stay anonymous
            </Button>
          </>
        ) : (
          <>
            <Button disabled={isUpdating} onClick={handleSwitchToAnonymous}>
              {isUpdating ? <>Switching...</> : <>Switch to anonymous mode</>}
            </Button>
            <Button
              type={buttonType.LINK}
              disabled={isUpdating}
              onClick={onClose}
            >
              Stay in public mode
            </Button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default IncognitoModal;
