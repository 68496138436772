import { Button } from "react-bootstrap";
import { useErrorBoundary } from "react-error-boundary";

const ErrorFallback = () => {
  const { resetBoundary } = useErrorBoundary();
  return (
    <div
      style={{
        textAlign: "center",
        padding: "25px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "20px",
      }}
    >
      <h1 style={{ color: "white", fontSize: "32px", fontWeight: 700 }}>
        Something went wrong
      </h1>
      <Button onClick={() => resetBoundary()}>Try again</Button>
    </div>
  );
};

export default ErrorFallback;
